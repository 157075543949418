import { Component, Input, OnInit, output } from "@angular/core";
import { ICoordinate } from "@ramudden/data-access/models/location";

@Component({
    selector: "app-map",
    templateUrl: "./map.component.html",
    styleUrl: "./map.component.scss",
    standalone: true,
})
export class MapComponent implements OnInit {
    @Input() coordinates: ICoordinate = undefined;
    coordinatesChanged = output<ICoordinate>();
    mapLoaded = output<void>();

    map: google.maps.Map;

    ngOnInit(): void {
        if (!this.coordinates) {
            this.getCurrentCoordinates();
        } else {
            this.initMap();
        }
    }

    async initMap(): Promise<void> {
        const { Map } = (await google.maps.importLibrary("maps")) as google.maps.MapsLibrary;

        if (!this.coordinates?.latitude || !this.coordinates?.longitude) {
            return;
        }

        this.map = new Map(document.getElementById("map") as HTMLElement, {
            center: {
                lat: this.coordinates.latitude,
                lng: this.coordinates.longitude,
            },
            zoom: 15,
            mapId: "e1ad0500d0a22f4d",
        });

        this.mapLoaded.emit();
    }

    public async createMarker(
        coordinates: ICoordinate,
        title: string,
        draggable = false,
    ): Promise<google.maps.marker.AdvancedMarkerElement> {
        const { AdvancedMarkerElement } = (await google.maps.importLibrary("marker")) as google.maps.MarkerLibrary;

        return new AdvancedMarkerElement({
            position: {
                lat: coordinates.latitude,
                lng: coordinates.longitude,
            },
            map: this.map,
            title: title,
            gmpDraggable: draggable,
        });
    }

    public getCurrentCoordinates() {
        if ("geolocation" in navigator) {
            let callBackSuccess: PositionCallback;
            if (!this.coordinates) {
                callBackSuccess = (position: GeolocationPosition) => {
                    const coordinate = {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    };

                    this.coordinatesChanged.emit(coordinate);
                    this.coordinates = coordinate;
                    this.initMap();
                };
            } else {
                callBackSuccess = (position: GeolocationPosition) => {
                    const coordinate = {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    };

                    this.coordinatesChanged.emit(coordinate);
                    this.coordinates = coordinate;
                };
            }

            const callBackError = (error: GeolocationPositionError) => {
                console.error(error);
            };
            navigator.geolocation.getCurrentPosition(callBackSuccess, callBackError);
        } else {
            alert("Geolocation is not available in this browser.");
            return undefined;
        }
    }
}
