<app-user-dialog
    #userDialog
    (nextClicked)="onUserDialogNext($event)"
/>

<app-team-dialog
    #teamDialog
    [disablePrevious]="disablePreviousTeamDialog"
    (nextClicked)="onTeamDialogClosed($event)"
    (previousClicked)="onTeamDialogPreviousClicked()"
/>

<app-vehicle-dialog
    #vehicleDialog
    (nextClicked)="onVehicleNext($event)"
    (previousClicked)="onVehiclePrevious()"
/>
