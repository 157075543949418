import { Component, inject, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { LoaderService } from "../../services/loader.service";

@Component({
    selector: "m-loader-icon",
    standalone: true,
    templateUrl: "./loader-icon.component.html",
    styleUrl: "./loader-icon.component.scss",
    imports: [],
})
export class LoaderIconComponent implements OnInit {
    isLoading: Observable<boolean>;
    private readonly loaderService = inject(LoaderService);

    ngOnInit() {
        this.isLoading = this.loaderService.loading$;
    }
}
