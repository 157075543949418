<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<content-stepper>
    <ng-container body>
        <m-stepper
            [steps]="[
                'parkingBanPage.general' | translate,
                'parkingBanPage.scannedSigns' | translate,
                'parkingBanPage.exceptions' | translate,
            ]"
            [withoutControls]="false"
            (finished)="onFinish()"
        >
            <ng-template stepIndex="0">
                <ng-container *ngTemplateOutlet="stepOne" />
            </ng-template>
            <ng-template stepIndex="1">
                <ng-container *ngTemplateOutlet="stepTwo" />
            </ng-template>
            <ng-template stepIndex="2">
                <ng-container *ngTemplateOutlet="stepThree" />
            </ng-template>
        </m-stepper>
    </ng-container>
</content-stepper>

<!--region:Steps-->

<!--region:Step 1-->
<ng-template #stepOne>
    <h2 class="mb-24">{{ "parkingBanPage.general" | translate }}</h2>

    <form
        class="flex flex-column gap-16"
        [formGroup]="parkingBanForm"
    >
        <div class="card">
            <div class="card__body">
                <div class="form__fields">
                    <!--region Name-->
                    <div class="form__field grid">
                        <label
                            class="grid__item-4"
                            for="name"
                        >
                            {{ "parkingBanPage.name" | translate }}
                        </label>

                        <div class="grid__item-8">
                            <input
                                class="input--text"
                                id="name"
                                formControlName="name"
                                placeholder=""
                                readonly
                                type="text"
                            />
                        </div>
                    </div>
                    <!--endregion-->

                    <!--region Description-->
                    <div class="form__field grid">
                        <label
                            class="grid__item-4"
                            for="description"
                        >
                            {{ "parkingBanPage.description" | translate }}
                        </label>

                        <div class="grid__item-8">
                            <input
                                class="input--text"
                                id="description"
                                formControlName="description"
                                placeholder=""
                                type="text"
                            />
                        </div>
                    </div>
                    <!--endregion-->

                    <!--region Start-->
                    <div class="form__field grid">
                        <label
                            class="grid__item-4"
                            for="start"
                        >
                            {{ "parkingBanPage.start" | translate }}
                        </label>

                        <div
                            class="grid__item-8"
                            (click)="startDateInput.showPicker()"
                        >
                            <input
                                class="input--text"
                                id="start"
                                #startDateInput
                                formControlName="start"
                                max="{{ parkingBanForm.get('end')?.value }}"
                                placeholder=""
                                type="date"
                            />
                        </div>
                    </div>
                    <!--endregion-->

                    <!--region End-->
                    <div class="form__field grid">
                        <label
                            class="grid__item-4"
                            for="end"
                        >
                            {{ "parkingBanPage.end" | translate }}
                        </label>

                        <div
                            class="grid__item-8"
                            (click)="endDateInput.showPicker()"
                        >
                            <input
                                class="input--text"
                                id="end"
                                #endDateInput
                                formControlName="end"
                                min="{{ parkingBanForm.get('start').value }}"
                                placeholder=""
                                type="date"
                            />
                        </div>
                    </div>
                    <!--endregion-->

                    <!--region Location-->
                    <div class="form__field grid">
                        <label
                            class="grid__item-4"
                            for="location"
                        >
                            {{ "parkingBanPage.location" | translate }}
                        </label>
                        <div class="grid__item-8 flex gap-8">
                            <input
                                class="input--text"
                                id="location"
                                disabled
                                readonly
                                type="text"
                                value="{{ parkingBanAddress }}"
                            />

                            <button
                                class="btn--yellow btn--icon"
                                (click)="appMap.getCurrentCoordinates()"
                            >
                                <m-svg iconName="location-crosshair" />
                            </button>
                        </div>
                    </div>

                    <div class="form__field">
                        <app-map
                            #appMap
                            [coordinates]="parkingBanLocation?.coordinate"
                            (coordinatesChanged)="onCoordinatesChanged($event)"
                            (mapLoaded)="onMapLoaded()"
                            style="--map-aspect-ratio: 2 / 1"
                        />
                    </div>

                    <!--endregion-->

                    <!--region Attachments-->
                    <ng-container formArrayName="attachments">
                        <div>
                            <button
                                class="btn--yellow"
                                (click)="addPhoto('photoInput')"
                                type="button"
                            >
                                {{ "parkingBanPage.addPhoto" | translate }}
                            </button>

                            <div class="attachments">
                                @for (attachmentForm of parkingBanAttachments.controls; track $index) {
                                    <ng-container [formGroup]="attachmentForm">
                                        <div class="attachment">
                                            <img
                                                class="attachment__image"
                                                [alt]="'attachment_' + $index"
                                                [src]="attachmentForm.get('attachmentImage').value"
                                            />

                                            <div class="attachment__body">
                                                <div class="form__field grid">
                                                    <label
                                                        class="grid__item-3"
                                                        for="attachmentDescription"
                                                    >
                                                        {{ "parkingBanPage.description" | translate }}
                                                    </label>

                                                    <div class="grid__item-7">
                                                        <input
                                                            id="attachmentDescription"
                                                            formControlName="attachmentDescription"
                                                            placeholder=""
                                                            type="text"
                                                        />
                                                    </div>

                                                    <button
                                                        class="btn--icon grid__item-2"
                                                        (click)="deleteAttachmentSnapshot($index)"
                                                        type="button"
                                                    >
                                                        <m-svg iconName="delete" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                }
                            </div>
                        </div>
                    </ng-container>
                    <!--endregion-->
                </div>
            </div>
        </div>
    </form>
</ng-template>
<!--endregion-->

<!--region:Step 2-->
<ng-template #stepTwo>
    <h2 class="mb-24">{{ "parkingBanPage.scannedSigns" | translate }}</h2>

    <form
        class="flex flex-column gap-16"
        [formGroup]="signScanFrom"
    >
        <div class="card">
            <div class="card__header flex justify-content-between align-items-start">
                <button
                    class="btn--yellow"
                    (click)="qrScanner.open($event)"
                >
                    {{ "parkingBanPage.scanSign" | translate }}
                </button>
            </div>
            <div class="card__body">
                <div class="form__fields">
                    @for (signForm of signs.controls; track $index) {
                        <ng-container [formGroup]="signForm">
                            <div class="attachment">
                                <button
                                    class="attachment__delete btn--icon"
                                    (click)="deleteSignSnapshot($index)"
                                >
                                    <m-svg iconName="delete" />
                                </button>

                                <img
                                    class="attachment__image"
                                    alt="{{ 'parkingBanPage.parkingBan' | translate }}"
                                    src="/assets/parking-ban.png"
                                />

                                <div class="attachment__body">
                                    <div class="form__field">
                                        <label for="qrCodeNumber">{{ "parkingBanPage.number" | translate }}</label>
                                        <input
                                            class="input--text"
                                            id="qrCodeNumber"
                                            formControlName="qrCodeNumber"
                                            placeholder=""
                                            type="text"
                                        />
                                    </div>
                                    <div class="form__field">
                                        <label for="signScanLocation">{{ "parkingBanPage.address" | translate }}</label>
                                        <input
                                            class="input--text"
                                            id="signScanLocation"
                                            [value]="getAddress(signForm.get('location').value)"
                                            readonly
                                            type="text"
                                        />
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    }
                </div>
            </div>
        </div>
    </form>
</ng-template>
<!--endregion-->

<!--region:Step 3-->
<ng-template #stepThree>
    <h2 class="mb-24">{{ "parkingBanPage.exceptions" | translate }}</h2>

    <form
        class="flex flex-column gap-16"
        [formGroup]="exceptionsForm"
    >
        <div class="card">
            <div class="card__body">
                <button
                    class="btn--yellow"
                    (click)="addPhoto('exceptionPhotoInput')"
                >
                    {{ "parkingBanPage.scanLicensePlate" | translate }}
                </button>
                <div class="form__fields">
                    @for (exceptionForm of exceptions.controls; track $index) {
                        <ng-container [formGroup]="exceptionForm">
                            <div class="attachment">
                                <button
                                    class="attachment__delete btn--icon"
                                    (click)="deleteExceptionSnapshot($index)"
                                >
                                    <m-svg iconName="delete" />
                                </button>

                                <img
                                    class="attachment__image"
                                    [alt]="exceptionForm.get('exceptionLicensePlate').value"
                                    [src]="exceptionForm.get('exceptionImage').value"
                                />

                                <div class="attachment__body">
                                    <div class="form__field">
                                        <label for="exceptionLicensePlate">{{
                                            "parkingBanPage.licensePlate" | translate
                                        }}</label>
                                        <input
                                            class="input--text"
                                            id="exceptionLicensePlate"
                                            formControlName="exceptionLicensePlate"
                                            placeholder=""
                                            type="text"
                                        />
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    }
                </div>
            </div>
        </div>
    </form>
</ng-template>
<!--endregion-->

<!--endregion-->

<app-qr-scanner
    #qrScanner
    (qrCodeScanned)="onQrCodeScanned($event)"
/>

<input
    id="photoInput"
    (change)="photoInputChanged($event)"
    accept="image/*"
    capture="environment"
    max="1"
    style="display: none"
    type="file"
/>

<input
    id="exceptionPhotoInput"
    (change)="exceptionPhotoInputChanged($event)"
    accept="image/*"
    capture="environment"
    max="1"
    style="display: none"
    type="file"
/>
