import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { ModalService } from "@ramudden/ui";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable()
export class NoInternetInterceptor implements HttpInterceptor {
    private readonly modalService = inject(ModalService);

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!navigator.onLine) {
            return throwError(() => this.modalService.error("No internet connection. Please check your network."));
        }

        return next.handle(req).pipe(
            catchError((error) => {
                return throwError(() => error);
            }),
        );
    }
}
