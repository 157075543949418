import { CommonModule } from "@angular/common";
import { Component, inject, input, output, ViewChild } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import {
    IPlannedAssignmentCustomTeamMember,
    IPlannedEvent,
    IPlannedWorker,
} from "@ramudden/data-access/models/planned-event";
import { IWorker } from "@ramudden/data-access/models/worker";
import { PlannedEventApi } from "@ramudden/data-access/resource/planned-event-api";
import { ModalComponent } from "@ramudden/ui";
import { firstValueFrom } from "rxjs";
import { ISelectedWorker, WorkerComponent } from "../../../../components/worker/worker.component";
import { AppService } from "../../../../services/app.service";

export interface TeamDialogOutput {
    selectedWorkers: ISelectedWorker[];
    excludedWorkers: ISelectedWorker[];
    splitTeam: boolean;
}

@Component({
    selector: "app-team-dialog",
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, ModalComponent, WorkerComponent, FormsModule, TranslateModule],
    templateUrl: "./team-dialog.component.html",
    styleUrls: ["./team-dialog.component.scss"],
})
export class TeamDialogComponent {
    disablePrevious = input<boolean>(false);
    allowClose = input<boolean>(false);
    usedInSetup = input<boolean>(true);
    showSplitTeam = input<boolean>(true);
    customTeam: IPlannedAssignmentCustomTeamMember[];

    @ViewChild(ModalComponent, { static: true }) dialog: ModalComponent;

    private readonly plannedEventApi = inject(PlannedEventApi);
    private readonly appService = inject(AppService);

    nextClicked = output<TeamDialogOutput>();
    previousClicked = output<void>();

    selectedPlannedEvent: IPlannedEvent;
    otherWorkers: ISelectedWorker[] = [];
    plannedWorkers: ISelectedWorker[] = [];
    splitTeam: boolean;

    allWorkers: IWorker[] = [];

    async openModal(customTeam?: IPlannedAssignmentCustomTeamMember[]) {
        this.customTeam = customTeam;
        this.allWorkers = await this.fetchAllWorkers();

        this.selectedPlannedEvent = this.appService.plannedEvent;
        this.plannedWorkers = this.appService.plannedEvent.plannedWorkers.map((plannedWorker) =>
            this.convertPlannedWorkerToSelectedWorker(plannedWorker),
        );

        this.dialog.openModal();
    }

    private async fetchAllWorkers() {
        const eventDate = this.appService.plannedEvent.eventDate;
        return await firstValueFrom(this.plannedEventApi.availableWorkersForDate$(eventDate));
    }

    protected onWorkerSelected(worker: ISelectedWorker) {
        if (this.plannedWorkers.some((plannedWorker) => plannedWorker.id === worker.id)) {
            this.plannedWorkers.find((plannedWorker) => plannedWorker.id === worker.id).isActive = !worker.isActive;
        } else {
            this.otherWorkers.find((otherWorker) => otherWorker.id === worker.id).isActive = !worker.isActive;
        }
    }

    protected showOtherWorkers() {
        const plannedWorkers = this.appService.plannedEvent.plannedWorkers.map((plannedWorker) => plannedWorker.worker);
        const otherWorkers = this.allWorkers.filter(
            (worker) => !plannedWorkers.some((plannedWorker) => plannedWorker.id === worker.id),
        );

        this.otherWorkers = otherWorkers
            .map((worker) => this.convertWorkerToSelectedWorker(worker))
            .sort((a, b) => (a.firstName > b.firstName ? 1 : -1));
    }

    protected hideOtherWorkers() {
        this.otherWorkers = [];
    }

    onPreviousClicked() {
        this.dialog.closeModal();
        this.previousClicked.emit();
    }

    navigateToVehicles() {
        const activePlannedWorkers = this.plannedWorkers.filter((worker) => worker.isActive);
        const activeConvertedPlannedWorkers = activePlannedWorkers.map((worker) => {
            const currentWorker = this.allWorkers.find((w) => w.id === worker.id);
            return this.convertWorkerToSelectedWorker(currentWorker);
        });

        const activeOtherWorkers = this.otherWorkers.filter((worker) => worker.isActive);
        const activeConvertedOtherWorkers = activeOtherWorkers.map((worker) => {
            const currentWorker = this.allWorkers.find((w) => w.id === worker.id);
            return this.convertWorkerToSelectedWorker(currentWorker);
        });

        const excludedPlannedWorkers = this.plannedWorkers.filter((worker) => !worker.isActive);
        const excludedConvertedPlannedWorkers = excludedPlannedWorkers.map((worker) => {
            const currentWorker = this.allWorkers.find((w) => w.id === worker.id);
            return this.convertWorkerToSelectedWorker(currentWorker);
        });

        const excludedOtherWorkers = this.otherWorkers.filter((worker) => !worker.isActive);
        const excludedConvertedOtherWorkers = excludedOtherWorkers.map((worker) => {
            const currentWorker = this.allWorkers.find((w) => w.id === worker.id);
            return this.convertWorkerToSelectedWorker(currentWorker);
        });

        this.dialog.closeModal();
        this.nextClicked.emit({
            selectedWorkers: [...activeConvertedPlannedWorkers, ...activeConvertedOtherWorkers],
            excludedWorkers: [...excludedConvertedPlannedWorkers, ...excludedConvertedOtherWorkers],
            splitTeam: this.splitTeam,
        });
    }

    //region Convert
    protected convertPlannedWorkerToSelectedWorker(plannedWorker: IPlannedWorker): ISelectedWorker {
        let isActive = false;
        if (this.customTeam?.length > 0) {
            isActive = this.customTeam.some(
                (customTeamMember) => customTeamMember.workerId === plannedWorker.worker.id,
            );
        } else {
            isActive = true;
        }

        return {
            isTeamLeader: plannedWorker.isTeamLeader,
            firstName: plannedWorker.worker.firstName,
            lastName: plannedWorker.worker.lastName,
            isActive: isActive,
            id: plannedWorker.worker.id,
        };
    }

    protected convertWorkerToSelectedWorker(worker: IWorker): ISelectedWorker {
        let isActive = false;
        if (this.customTeam?.length > 0) {
            isActive = this.customTeam.some((customTeamMember) => customTeamMember.workerId === worker.id);
        }

        return {
            isTeamLeader: false,
            firstName: worker.firstName,
            lastName: worker.lastName,
            isActive: isActive,
            id: worker.id,
            organizationId: worker.organizationId,
            canDoControl: worker.canDoControl,
        };
    }
    //endregion
}
