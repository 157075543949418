import { Pipe, PipeTransform } from "@angular/core";
import { DomainDataService } from "../services/generic/domain-data.service";

@Pipe({ name: "translateModel", standalone: true })
export class TranslateModelPipe implements PipeTransform {
    constructor(private readonly domainDataService: DomainDataService) {}

    transform(value: string, language: string): string {
        return this.domainDataService.translate(value, language);
    }
}
