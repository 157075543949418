<m-modal
    [canClose]="false"
    title="{{ 'vehicleDialog.selectVehicles' | translate }}"
>
    <ng-container body>
        <h3 class="mb-16">{{ "vehicleDialog.selectConfiguredVehicles" | translate }}:</h3>

        <div class="vehicles mb-24">
            @for (defaultVehicle of defaultVehicles; track defaultVehicle.deviceCode) {
                <app-vehicle
                    [vehicle]="defaultVehicle"
                    (vehicleSelected)="onVehicleSelected($event)"
                />
            }
        </div>

        <h3 class="mb-16">{{ "vehicleDialog.selectFromOtherVehicles" | translate }}:</h3>

        <div class="mb-16">
            <input
                class="input--text"
                [formControl]="searchControl"
                placeholder="Search vehicle"
                type="text"
            />
        </div>

        <div class="vehicles mb-24">
            @for (otherVehicle of filteredVehicles$ | async; track otherVehicle.deviceCode) {
                <app-vehicle
                    [vehicle]="otherVehicle"
                    (vehicleSelected)="onVehicleSelected($event)"
                />
            }
        </div>

        <h3 class="mb-16">Wjet:</h3>

        <div class="vehicles">
            @for (wjet of wJetVehicles; track wjet.deviceCode) {
                <app-vehicle
                    [vehicle]="wjet"
                    (vehicleSelected)="onVehicleSelected($event)"
                />
            }
        </div>
    </ng-container>

    <ng-container
        #footer
        footer
    >
        <div class="flex justify-content-between align-items-center gap-8">
            @if (usedInSetup()) {
                <button
                    class="btn"
                    (click)="onPreviousClicked()"
                >
                    {{ "Vorige" }}
                </button>
            }
            <button
                class="btn--yellow ml-auto"
                (click)="closeModal()"
            >
                {{ "Save" }}
            </button>
        </div>
    </ng-container>
</m-modal>
