import { CommonModule } from "@angular/common";
import { Component, input, output } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SvgComponent } from "@ramudden/ui";
import { ISelectedVehicle } from "../../pages/setup/dialogs/vehicle/vehicle-dialog.component";

@Component({
    selector: "app-vehicle",
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, SvgComponent, FormsModule],
    templateUrl: "./vehicle.component.html",
    styleUrl: "./vehicle.component.scss",
})
export class VehicleComponent {
    vehicleSelected = output<ISelectedVehicle>();
    vehicle = input.required<ISelectedVehicle>();
}
