import { Component, inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from '../services/app.service';
import { IPlannedEvent } from "@ramudden/data-access/models/planned-event";
import moment from "moment";

@Component({
    standalone: true,
    selector: "app-root",
    templateUrl: "./root.component.html",
})
export class RootComponent implements OnInit {
    private readonly appService = inject(AppService);
    private readonly router = inject(Router);

    ngOnInit() {
        const plannedEvent = this.appService?.plannedEvent;

        if (this.appService.selectedWorker && this.plannedEventStillValid(plannedEvent)) {
            return this.router.navigate(["assignments"]);
        }

        this.appService.clearState();
        return this.router.navigate(["setup"]);
    }

    plannedEventStillValid(plannedEvent?: IPlannedEvent) {
        if (!plannedEvent) return false;

        const now = moment();

        const tomorrow = moment(plannedEvent.eventDate).add(1, "days");
        const shiftEndTime = plannedEvent.isNightShift
            ? moment(tomorrow).set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
            : moment(tomorrow).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

        return now < shiftEndTime;
    }
}
