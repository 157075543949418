import { Component, inject, NgZone, OnDestroy, output, ViewChild } from "@angular/core";
import { ModalComponent, ToastService } from "@ramudden/ui";
import QrScanner from "qr-scanner";

@Component({
    selector: "app-qr-scanner",
    templateUrl: "./qr-scanner.component.html",
    imports: [ModalComponent],
    standalone: true,
})
export class QrScannerComponent implements OnDestroy {
    @ViewChild("qrVideo") videoElement: any;
    @ViewChild(ModalComponent, { static: true }) modal!: ModalComponent;

    onSuccess: ((result: string) => void) | undefined;
    qrScanner: QrScanner | undefined;

    private readonly ngZone = inject(NgZone);
    private readonly toastService = inject(ToastService);

    qrCodeScanned = output<string>();

    ngOnDestroy() {
        this.qrScanner?.destroy();
        this.qrScanner = undefined;
    }

    open(event: Event) {
        this.modal.openModal(event);
        setTimeout(() => {
            this.createScanner();
        }, 1000);
    }

    createScanner() {
        if (!this.qrScanner) {
            this.qrScanner = new QrScanner(
                this.videoElement.nativeElement,
                (result) => {
                    if (result && result.data) {
                        this.qrScanner?.stop();
                        this.ngZone.run(() => {
                            this.handleScanDeviceMode(result.data);
                            this.modal.closeModal();
                        });
                    }
                },
                {
                    onDecodeError: (error: Error | string) => {
                        if (typeof error === "string" && error.includes("No QR code found")) {
                            return;
                        }
                        console.error(error);
                        this.toastService.showError("Error while scanning QR code");
                    },
                    highlightScanRegion: true,
                    highlightCodeOutline: true,
                },
            );
        }
        this.qrScanner.start();
    }

    handleScanDeviceMode(data: string) {
        this.qrCodeScanned.emit(data);
        this.qrScanner?.destroy();
        this.qrScanner = undefined;
    }
}
