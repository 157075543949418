import { CommonModule, NgOptimizedImage } from "@angular/common";
import { Component, computed, inject, input, OnInit, output } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { AssignmentStatus } from "@ramudden/data-access/models/assignment";
import { IAttachment } from "@ramudden/data-access/models/attachment";
import { IPlannedAssignment } from "@ramudden/data-access/models/planned-event";
import {
    FilterDescriptor,
    FilterOperator,
    SearchParameters,
    ServiceRequestOptions,
} from "@ramudden/data-access/models/search";
import { JournalApi } from "@ramudden/data-access/resource/journal.api";
import { IconName, SvgComponent, ToastService } from "@ramudden/ui";
import { firstValueFrom } from "rxjs";
import { ImageViewerDialogComponent } from "../../../pages/assignment-container/assignment-details/dialogs/image-viewer/image-viewer-dialog.component";
import { PdfViewerDialogComponent } from "../../../pages/assignment-container/assignment-details/dialogs/pdf-viewer/pdf-viewer-dialog.component";
import { TranslateEnumPipe } from "../../../pipes/translate-enum.pipe";

@Component({
    selector: "app-assignment-card",
    standalone: true,
    imports: [CommonModule, NgOptimizedImage, SvgComponent, TranslateEnumPipe, TranslateModule],
    templateUrl: "./assignment-card.component.html",
    styleUrl: "./assignment-card.component.scss",
})
export class AssignmentCardComponent implements OnInit {
    private readonly toastService = inject(ToastService);
    private readonly journalApi = inject(JournalApi);

    attachments: IAttachment[] = [];

    plannedAssignment = input.required<IPlannedAssignment>();
    imageViewer = input.required<ImageViewerDialogComponent>();
    pdfViewer = input.required<PdfViewerDialogComponent>();
    assignmentClicked = output<IPlannedAssignment>();

    assignment = computed(() => {
        return this.plannedAssignment().assignment;
    });

    name = computed(() => {
        if (this.assignment()?.parentAssignment)
            return `${this.assignment()?.parentAssignment.name} - ${this.assignment().name}`;
        else {
            return this.assignment()?.name;
        }
    });

    organization = computed(() => {
        return (
            this.assignment().project?.organization?.name ||
            this.assignment().parentAssignment?.project?.organization?.name
        );
    });

    description = computed(() => {
        if (this.assignment().parentAssignment) {
            return `${this.assignment().description} - ${this.assignment().parentAssignment.description}`;
        }
        return this.assignment().description;
    });

    address = computed(() => {
        const address = this.assignment().location.address;
        return `${address.line1 ?? ""}, ${address.zipCode ?? ""} ${address.city ?? ""}`;
    });

    isMachineWork = computed(() => {
        return this.assignment().isMachineWork;
    });

    protected getGoogleMapsLink(): string {
        const coordinate = this.assignment().location.coordinate;

        return `https://www.google.com/maps/place/${coordinate.latitude},${coordinate.longitude}/@${coordinate.latitude},${coordinate.longitude},17z`;
    }

    getStatusColor(): string {
        const statusId = this.assignment()?.currentStatus?.statusId;

        if (!statusId) {
            return "var(--color-default)";
        }

        switch (statusId) {
            case AssignmentStatus.Cancelled:
                return "var(--color-not-started)";
            case AssignmentStatus.Finished:
                return "var(--color-finished)";
            case AssignmentStatus.InProgress:
                return "var(--color-in-progress)";
            default:
                return "var(--color-default)";
        }
    }

    ngOnInit() {
        this.fetchAttachments();
    }

    private async fetchAttachments() {
        const serviceRequestOptions = new ServiceRequestOptions();
        serviceRequestOptions.includes.add("Journal", "Attachments");

        const searchParameters = new SearchParameters();
        searchParameters.filter = [new FilterDescriptor("IsAdminOnly", false, FilterOperator.equals)];

        const journals = (
            await firstValueFrom(
                this.journalApi.getForAssignment$(this.assignment().id, serviceRequestOptions, searchParameters),
            )
        ).data;

        if (this.assignment().project?.id || this.assignment().parentAssignment?.project?.id) {
            const projectId = this.assignment().project?.id || this.assignment().parentAssignment?.project?.id;
            const projectJournals = (
                await firstValueFrom(this.journalApi.getForProject$(projectId, serviceRequestOptions, searchParameters))
            ).data;
            journals.push(...projectJournals);
        }

        this.attachments = journals.map((x) => x.attachments).flat();
    }

    isImage(fileName: string): boolean {
        return (
            fileName.toLowerCase().endsWith(".jpg") ||
            fileName.toLowerCase().endsWith(".jpeg") ||
            fileName.toLowerCase().endsWith(".png")
        );
    }

    getIconName(attachment: IAttachment): IconName {
        if (attachment.url.toLowerCase().endsWith(".pdf")) return "file-pdf-light";
        else if (this.isImage(attachment.url)) return "file-image";
        else return "file";
    }

    openPreview(attachment: IAttachment, event: Event) {
        if (attachment.url.toLowerCase().endsWith(".pdf"))
            this.pdfViewer().openModal(attachment.name, attachment.url, event);
        else if (this.isImage(attachment.url)) this.imageViewer().openModal(attachment.name, attachment.url, event);
        else this.toastService.showInfo("Only images and pdf files can be previewed");
        event.stopPropagation();
    }
}
