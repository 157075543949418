import { CommonModule } from "@angular/common";
import { Component, inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { IParkingBan } from "@ramudden/data-access/models/parking-ban";
import { ModalService, SvgComponent } from "@ramudden/ui";
import moment from "moment";
import { LoaderIconComponent } from "../../../components/loader-icon/loader-icon.component";
import { DefaultComponent } from "../../../layout/content/default/default.component";
import { AssignmentService } from "../../../services/assignment.service";
import { LocationService } from "../../../services/location.service";
import { ParkingBanService } from "../../../services/parking-ban.service";

@Component({
    selector: "app-assignment-parking-bans",
    standalone: true,
    imports: [CommonModule, SvgComponent, DefaultComponent, TranslateModule, LoaderIconComponent],
    templateUrl: "./assignment-parking-bans.component.html",
    styleUrl: "./assignment-parking-bans.component.scss",
})
export class AssignmentParkingBansComponent implements OnInit {
    private readonly assignmentService = inject(AssignmentService);
    private readonly locationService = inject(LocationService);
    private readonly parkingBanService = inject(ParkingBanService);
    private readonly router = inject(Router);
    private readonly modalService = inject(ModalService);

    parkingBans: IParkingBan[] = [];

    get siteActive() {
        const plannedAssignment = this.assignmentService.selectedPlannedAssignment;
        return (
            plannedAssignment.currentTeamStatus?.teamArrival !== undefined &&
            plannedAssignment.currentTeamStatus?.teamDeparture === undefined
        );
    }

    numberPlates(parkingBan: IParkingBan): string {
        return parkingBan.exceptions.map((e) => e.licensePlate).join(", ");
    }

    address(parkingBan: IParkingBan): string {
        const address = parkingBan.location.address;
        if (address) {
            return this.locationService.convertToAddressString(address);
        }
        return "";
    }

    async ngOnInit() {
        this.parkingBans = await this.fetchParkingBans();
    }

    //region Actions
    async onRefresh() {
        this.parkingBans = await this.fetchParkingBans();
    }

    createParkingBan() {
        const plannedAssignmentId = this.assignmentService.selectedPlannedAssignment.id;
        this.router.navigate(["/assignment", plannedAssignmentId, "parking-ban", "create"]);
    }

    editParkingBan(parkingBan: IParkingBan) {
        const plannedAssignmentId = this.assignmentService.selectedPlannedAssignment.id;
        this.router.navigate(["/assignment", plannedAssignmentId, "parking-ban", parkingBan.id]);
    }

    async deleteParkingBan(parkingBan: IParkingBan) {
        const answer = await this.modalService.confirmAsync("Are you sure you want to delete this parking ban?");
        if (!answer) {
            return;
        }
        this.parkingBanService.deleteParkingBan(parkingBan.id).then(() => this.onRefresh());
    }

    //region Fetch data
    private async fetchParkingBans(): Promise<IParkingBan[]> {
        const assignmentId = this.assignmentService.selectedPlannedAssignment.assignmentId;
        if (assignmentId <= 0) return Promise.resolve([] as IParkingBan[]);

        const parkingBans = await this.parkingBanService.getAllParkingBans(assignmentId);
        return parkingBans.sort((a, b) => {
            const aTime: number = moment(a.name, "YYYYMMDD_HHmmss").toDate().getTime();
            const bTime: number = moment(b.name, "YYYYMMDD_HHmmss").toDate().getTime();
            return bTime - aTime;
        });
    }

    //endregion
}
