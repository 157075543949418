<content-default>
    <ng-container body>
        <h2 class="mb-24">{{ "taskPage.tasks" | translate }}</h2>

        <div class="flex flex-column gap-16">
            @for (task of tasks; track task.id) {
                <app-task-card
                    [task]="task"
                    (refreshTasks)="onRefreshTasks()"
                />
            }

            @if (tasks.length === 0) {
                <div class="flex flex-column items-center">
                    <m-svg
                        iconName="empty"
                        size="large"
                    />
                    <p class="text-center">{{ "taskPage.noTasks" | translate }}</p>
                </div>
            }
        </div>
    </ng-container>
    <ng-container floating-action>
        @if (siteActive) {
            <button
                class="btn--floating-action"
                (click)="createTask()"
                title="{{ 'taskPage.createTask' | translate }}"
            >
                <m-svg iconName="plus" />
            </button>
        }
    </ng-container>
</content-default>
