import { CommonModule } from "@angular/common";
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    ElementRef,
    input,
    output,
    ViewChild,
} from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { ModalConfig, ModalConfigAction } from "../modal";
import { SvgComponent } from "../svg";

@Component({
    selector: "m-modal",
    standalone: true,
    imports: [CommonModule, SvgComponent, TranslateModule],
    templateUrl: "./modal.component.html",
    styleUrl: "./modal.component.scss",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent implements AfterViewInit {
    @ViewChild("modal", { static: true }) modalElement!: ElementRef<HTMLDialogElement>;
    @ViewChild("modalContent", { static: true }) modalContentElement!: ElementRef;

    config?: ModalConfig;
    doNotShowAgain = false;
    hasFooter = false;

    close = output<() => void>();

    @ContentChild("footer") set setFooter(footer: HTMLElement) {
        this.hasFooter = !!footer;
    }

    title = input.required<string>();
    canClose = input<boolean>(true);

    get modalOpen() {
        return this.modalElement.nativeElement.open;
    }

    openModal(event?: Event) {
        if (event) event.stopPropagation();
        this.modalElement.nativeElement.showModal();
    }

    closeModal(action?: ModalConfigAction) {
        if (action) {
            this.close.emit(action.handler);
        }
        this.modalElement.nativeElement.close();
    }

    isOutsideOfElement(event: Event, element: any) {
        return !element.contains(event.target);
    }

    ngAfterViewInit() {
        if (this.canClose()) {
            window.addEventListener("click", (event) => {
                const userClickedOutside = this.isOutsideOfElement(event, this.modalContentElement.nativeElement);

                if (userClickedOutside) {
                    this.closeModal();
                }
            });
        }
    }
}
