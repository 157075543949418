import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { AppService } from '../services/app.service';
import { Observable } from "rxjs";

@Injectable()
export class AddWorkerInterceptor implements HttpInterceptor {
    private readonly appService = inject(AppService);

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.appService.selectedWorker?.id) {
            const requestClone = req.clone({
                setHeaders: {
                    Worker: this.appService.selectedWorker.id.toString(),
                },
            });
            return next.handle(requestClone);
        }
        return next.handle(req);
    }
}
