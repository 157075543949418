import { NgClass, NgIf } from "@angular/common";
import { Component, inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormArray, FormGroup, ReactiveFormsModule, UntypedFormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { SubscriptionManager } from "@ramudden/core/utils";
import { SigncoFormGroup } from "@ramudden/data-access/models/form";
import {
    IAddress,
    ICoordinate,
    ILocation,
    LocationCreator,
    LocationUpdater,
} from "@ramudden/data-access/models/location";
import { SearchParameters, SortDescriptor, SortDirection } from "@ramudden/data-access/models/search";
import { ISignCategory } from "@ramudden/data-access/models/signcategory";
import {
    DimensionOption,
    ITask,
    ITaskStatusHistory,
    MarkingPropertyTypeOption,
    PrimitiveOption,
    SignColorOption,
    SignMaterial,
    TaskCreator,
    TaskPropertyConfiguration,
    TaskStatus,
    TaskUpdater,
    Unit,
    WorkerTaskCreator,
} from "@ramudden/data-access/models/task";
import { ISignMaterialInfo, ITaskRule } from "@ramudden/data-access/models/task-rule";
import { IWorker } from "@ramudden/data-access/models/worker";
import { LocationApi } from "@ramudden/data-access/resource/location.api";
import { SignCategoryApi } from "@ramudden/data-access/resource/signcategory.api";
import { TaskRuleApi } from "@ramudden/data-access/resource/task-rule.api";
import { TaskApi } from "@ramudden/data-access/resource/task.api";
import { WorkerApi } from "@ramudden/data-access/resource/worker.api";
import { FormValidationService } from "@ramudden/services";
import { SvgComponent, ToastService } from "@ramudden/ui";
import moment from "moment/moment";
import { firstValueFrom, map } from "rxjs";
import { DropdownComponent } from "../../../../components/dropdown/dropdown.component";
import { DropdownOption } from "../../../../components/dropdown/dropdown.model";
import { FormValidationComponent } from "../../../../components/form-validation/form-validation.component";
import { DefaultComponent } from "../../../../layout/content/default/default.component";
import { SafeHtmlPipe } from "../../../../pipes/safe-html.pipe";
import { TranslateModelPipe } from "../../../../pipes/translate-model.pipe";
import { AssignmentService } from "../../../../services/assignment.service";
import { DomainDataService } from "../../../../services/generic/domain-data.service";
import { GlobalEventsService } from "../../../../services/generic/global-events.service";
import { LocationService } from "../../../../services/location.service";
import { TaskService } from "../../../../services/task.service";
import { TitleService } from "../../../../services/title.service";
import { MapsDialogComponent } from "../../assignment-parking-bans/edit-parking-ban/dialogs/maps/maps-dialog.component";

@Component({
    standalone: true,
    selector: "app-task-details",
    templateUrl: "./task-details.component.html",
    imports: [
        DefaultComponent,
        ReactiveFormsModule,
        MapsDialogComponent,
        NgIf,
        TranslateModule,
        DropdownComponent,
        FormValidationComponent,
        NgClass,
        SafeHtmlPipe,
        TranslateModelPipe,
        SvgComponent,
    ],
})
export class TaskDetailsComponent implements OnInit, OnDestroy {
    task: ITask;

    submitting: boolean;
    taskForm: SigncoFormGroup;
    taskStatusForm: SigncoFormGroup;

    taskStatuses: DropdownOption<string>[];
    filteredTaskStatuses: DropdownOption<string>[];
    taskTypes: DropdownOption<string>[];
    signMaterials: DropdownOption<string>[];
    signCategoryValues: DropdownOption<string>[];
    signCategoryLevel2Values: DropdownOption<string>[];
    signCategoryLevel3Values: DropdownOption<string>[];
    signCategoryLevel4Values: DropdownOption<string>[];
    allSubstrateTypes: DropdownOption<string>[];
    workers: DropdownOption<number>[];

    propertyConfiguration = new TaskPropertyConfiguration();

    selectMaterial = false;
    selectColor: boolean;
    selectIsMachineWork: boolean;
    selectCanHavePrimer: boolean;
    selectShowSubstrate: boolean;
    selectShowCategories: boolean;
    selectCanBeRefreshed: boolean;
    selectLevel2Category: boolean;
    selectLevel3Category: boolean;
    selectLevel4Category: boolean;
    generatedName: string;

    displayCustomLength: boolean;
    displayCustomWidth: boolean;
    displayCustomColoredWidth: boolean;
    displayCustomColoredLength: boolean;
    displayCustomDimension: boolean;
    displayCustomDiameter: boolean;
    displayCustomBlock1Width: boolean;
    displayCustomBlock2Width: boolean;
    displayCustomLDimension: boolean;
    displayCustomTDimension: boolean;
    displayCustomHeight: boolean;
    displayCustomText: boolean;
    displayCustomSpeed: boolean;
    displayCustomSignColor: boolean;

    hideWidthList: boolean;
    hideLengthList: boolean;
    hideHeightList: boolean;
    hideColoredWidthList: boolean;
    hideColoredLengthList: boolean;
    hideDiameterList: boolean;
    hideBlock1WidthList: boolean;
    hideBlock2WidthList: boolean;
    hideSpeedList: boolean;
    hideTextList: boolean;
    hideDimensionList: boolean;
    hideLElementDimensionList: boolean;
    hideTElementDimensionList: boolean;

    widthOptions: DropdownOption<string>[];
    lengthOptions: DropdownOption<string>[];
    coloredWidthOptions: DropdownOption<string>[];
    coloredLengthOptions: DropdownOption<string>[];
    typeOptions: DropdownOption<string>[];
    diameterOptions: DropdownOption<string>[];
    block1WidthOptions: DropdownOption<string>[];
    block2WidthOptions: DropdownOption<string>[];
    heightOptions: DropdownOption<string>[];
    textOptions: DropdownOption<string>[];
    speedOptions: DropdownOption<string>[];
    dimensionOptions: DropdownOption<DimensionOption>[];
    lElementDimensionOptions: DropdownOption<DimensionOption>[];
    tElementDimensionOptions: DropdownOption<DimensionOption>[];
    signColorOptions: DropdownOption<string>[];

    defaultSignColor = "#ffffff";

    private allSignMaterials: DropdownOption<string>[];
    private allSignCategories: ISignCategory[];
    private allSignCategoryValues: DropdownOption<string>[];
    private allMarkingPropertyTypeValues: DropdownOption<string>[];
    private allSignColorOptions: DropdownOption<string>[];
    private allUnits: DropdownOption<string>[];

    protected currentSignCategory: ISignCategory;
    private currentTaskRule: ITaskRule;
    private taskRules: ITaskRule[];

    private taskRuleIsSet: boolean;
    private readonly subscriptionManager = new SubscriptionManager();

    currentDate: Date;
    signColors = [
        { label: "taskPage.white", value: "#f6f6f6" },
        { label: "taskPage.black", value: "#1e1e1e" },
        { label: "taskPage.yellow", value: "#f0ca00" },
        { label: "taskPage.green", value: "#008754" },
        { label: "taskPage.lightGreen", value: "#48a43f" },
        { label: "taskPage.red", value: "#c1121c" },
        { label: "taskPage.blue", value: "#0e518d" },
        { label: "taskPage.lightBlue", value: "#3481b8" },
        { label: "taskPage.reflectingBlue", value: "#81c0bb" },
        { label: "taskPage.orange", value: "#e15501" },
        { label: "taskPage.ochre", value: "#ba8f4c" },
        { label: "taskPage.purple", value: "#992572" },
        { label: "taskPage.grey", value: "#8f9695" },
    ] as DropdownOption<string>[];

    private readonly titleService = inject(TitleService);
    private readonly assignmentService = inject(AssignmentService);
    private readonly domainDataService = inject(DomainDataService);
    private readonly formBuilder = inject(UntypedFormBuilder);
    private readonly locationService = inject(LocationService);
    private readonly route = inject(ActivatedRoute);
    private readonly router = inject(Router);
    private readonly signCategoryApi = inject(SignCategoryApi);
    private readonly taskApi = inject(TaskApi);
    private readonly taskRulesApi = inject(TaskRuleApi);
    private readonly workerApi = inject(WorkerApi);
    readonly formValidationService = inject(FormValidationService);
    readonly globalEventsService = inject(GlobalEventsService);
    readonly translateService = inject(TranslateService);
    private readonly locationApi = inject(LocationApi);
    private readonly taskService = inject(TaskService);
    private readonly toastService = inject(ToastService);

    title: string;

    async ngOnInit() {
        this.subscriptionManager.add("routeChanges", this.subscribeToRouteChanges());
        const plannedAssignmentId = this.route.snapshot.params["plannedAssignmentId"];
        this.titleService.goBack.set(["assignment", plannedAssignmentId, "tasks"]);
        this.titleService.title = "Task";

        let task: ITask;
        if (this.isCreatingNewTask()) {
            task = {} as ITask;
            task.location = {} as ILocation;
            task.assignment = this.assignmentService.selectedPlannedAssignment.assignment;
            task.currentStatus = {} as ITaskStatusHistory;
        } else {
            task = await this.taskService.fetchTask(this.route.snapshot.params["taskId"]);
            this.generatedName = task.name;
        }
        this.task = task;
        this.setTitle();

        this.currentDate = new Date();
        await this.initDropdowns();
        this.filterTaskStatuses();

        this.initialize().then(async () => {
            if (this.isCreatingNewTask()) {
                const coordinates = await this.locationService.getCurrentCoordinates();
                const address = await this.locationService.getAddressFromCoordinates(coordinates);
                task.location.coordinate = coordinates;
                task.location.address = address.address;
                this.taskForm.get("location").patchValue(task.location);
            }
        });
    }

    subscribeToRouteChanges() {
        return this.route.params.subscribe(async (params) => {
            if (this.task && !this.isCreatingNewTask()) {
                this.task = await this.taskService.fetchTask(params["taskId"]);
                this.generatedName = this.task.name;
                this.initialize();
                this.setTitle();
            }
        });
    }

    setTitle() {
        if (!this.isCreatingNewTask()) {
            if (this.task?.assignment?.parentAssignment) {
                this.title = `${this.task.assignment.parentAssignment.name} - ${this.task.assignment.name} - ${this.task.name}`;
            } else if (this.task?.assignment) {
                this.title = `${this.task.assignment.name} - ${this.task.name}`;
            } else {
                this.title = this.task.name;
            }
        }
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
    }

    // Depending on the selected type,
    // display corresponding materials (or categories if no material for the type)
    handleTaskTypeChange(event: string) {
        const rule = this.taskRules ? this.taskRules.find((r) => r.taskTypeId === event) : undefined;
        if (!rule) return;

        this.refreshTaskRule(rule);

        this.setCategoryValues(undefined);

        this.resetFormControlValues(
            "signMaterialId",
            "signCategoryId",
            "signCategoryLevel2Id",
            "signCategoryLevel3Id",
            "signCategoryLevel4Id",
            "isMachineWork",
            "isNew",
            "customSignColor",
            "signColorId",
        );

        if (!this.selectShowCategories) {
            this.taskStatusForm.controls["signCategoryId"].patchValue(this.signCategoryValues[0].value);
            this.handleCategoryChange(this.signCategoryValues[0].value);
        }
        this.refreshRequiredValidator("signMaterialId", () => this.selectMaterial);
    }

    // Depending on the selected material,
    // display corresponding categories
    handleMaterialChange(event: SignMaterial) {
        const currentMaterial = this.getMaterialInfo(event);
        this.setSelectIsMachineWork(currentMaterial);
        this.setSelectCanHavePrimer(currentMaterial);
        this.setSelectColor(currentMaterial);
        this.resetCategoryLevels();
        this.setCategoryValues(currentMaterial);
        this.resetFormControlValues("signCategoryId", "isMachineWork", "customSignColor", "signColorId");
    }

    // Depending on the selected category,
    // display corresponding sublevel categories
    handleCategoryChange(event: string, initialCall = false) {
        const currentCategory = this.allSignCategories.find((m) => m.signCategoryId === event);
        this.setLevel2Category(currentCategory, initialCall);
        this.setLevel3Category(undefined, initialCall);
        this.setLevel4Category(undefined, initialCall);
    }

    handleCategoryLevel2Change(event: string, initialCall = false) {
        const currentCategory = this.allSignCategories.find((m) => m.signCategoryId === event);
        this.setLevel3Category(currentCategory, initialCall);
        this.setLevel4Category(undefined, initialCall);
    }

    handleCategoryLevel3Change(event: string, initialCall = false) {
        const currentCategory = this.allSignCategories.find((m) => m.signCategoryId === event);

        this.setLevel4Category(currentCategory, initialCall);
    }

    handleCategoryLevel4Change(event: string, initialCall = false) {
        const currentCategory = this.allSignCategories.find((m) => m.signCategoryId === event);

        // if category is present immediately configure because there is no level 5
        if (currentCategory) {
            this.currentSignCategory = currentCategory;
        }
        if (currentCategory && !initialCall) {
            this.applyNewPropertyConfiguration(currentCategory, false);
        }
    }

    // Allowed sign categories are different for machine work and handwork
    handleIsMachineWorkChange() {
        const materialId = this.taskStatusForm.controls["signMaterialId"].value;
        const currentMaterial = this.currentTaskRule.signMaterialInfo.find((m) => m.signMaterialId === materialId);
        this.setCategoryValues(currentMaterial);
        this.resetFormControlValues("signCategoryId");
        this.resetCategoryLevels();
    }

    handleSubstrateTypeChange(event: string) {
        if (event == null) {
            this.taskStatusForm.controls["substrateTypeOverride"].enable();
        } else {
            this.taskStatusForm.controls["substrateTypeOverride"].disable();
        }
        this.refreshRequiredValidator("substrateTypeOverride", () => event == null);
    }

    handleWidthChange(event: string) {
        this.displayCustomWidth = event == null;
    }

    handleLengthChange(event: string) {
        this.displayCustomLength = event == null;
    }

    handleHeightChange(event: string) {
        this.displayCustomHeight = event == null;
    }

    handleColoredWidthChange(event: string) {
        this.displayCustomColoredWidth = event == null;
    }

    handleColoredLengthChange(event: string) {
        this.displayCustomColoredLength = event == null;
    }

    handleDiameterChange(event: string) {
        this.displayCustomDiameter = event == null;
    }

    handleBlock1WidthChange(event: string) {
        this.displayCustomBlock1Width = event == null;
    }

    handleBlock2WidthChange(event: string) {
        this.displayCustomBlock2Width = event == null;
    }

    handleSpeedChange(event: string) {
        this.displayCustomSpeed = event == null;
    }

    handleTextChange(event: string) {
        this.displayCustomText = event == null;
    }

    handleDimensionChange(event: string) {
        this.displayCustomDimension = event == null;
    }

    handleLElementDimensionChange(event: DropdownOption<DimensionOption>) {
        this.displayCustomLDimension = event == null;
    }

    handleTElementDimensionChange(event: string) {
        this.displayCustomTDimension = event == null;
    }

    //#region [Reactive form setup]
    private async initialize() {
        if (!this.task) return;

        this.taskStatusForm = this.formBuilder.group({
            substrateTypeOverride: [{ value: "", disabled: true }],
            substrateTypeId: [null],
            taskTypeId: [null, Validators.required],
            signMaterialId: null,
            signCategoryId: [null, Validators.required],
            signCategoryLevel2Id: null,
            signCategoryLevel3Id: null,
            signCategoryLevel4Id: null,
            isMachineWork: null,
            primer: null,
            isNew: null,
            hoursCount: null,
            minutesCount: null,
            note: "",
            customSignColor: "",
            signColorId: null,
            markingsCount: null,
            length: null,
            customLength: null,
            width: null,
            customWidth: null,
            coloredLength: null,
            customColoredLength: null,
            coloredWidth: null,
            customColoredWidth: null,
            customDimensionWidth: null,
            customDimensionLength: null,
            customDimensionDescription: null,
            dropdownDimension: null,
            type: "",
            bannerCount: null,
            logoCount: null,
            diameter: null,
            customDiameter: null,
            dropdownTElementDimension: null,
            customTElementLength: null,
            customTElementWidth: null,
            dropdownLElementDimension: null,
            customLElementLength: null,
            customLElementWidth: null,
            tElementCount: null,
            lElementCount: null,
            tramElementCount: null,
            height: null,
            customHeight: null,
            text: "",
            customText: "",
            speed: null,
            customSpeed: null,
            placenumber: "",
            block1Amount: null,
            block1Width: null,
            customBlock1Width: null,
            block2Amount: null,
            block2Width: null,
            customBlock2Width: null,
            lengthA: null,
            lengthB: null,
            lengthC: null,
            lengthD: null,
            whiteSubstrateLength: null,
            whiteSubstrateWidth: null,
            whiteBandCount: null,
            redBandCount: null,
            yellowBandCount: null,
            orangeBandCount: null,
            greenBandCount: null,
            blueBandCount: null,
            purpleBandCount: null,
            bendCount: null,
            arrowHeadCount: null,
            roundedEndCount: null,
            shortPieceCount: null,
        }) as SigncoFormGroup;

        this.taskForm = this.formBuilder.group({
            assignmentId: [null, Validators.required],
            name: ["", Validators.required],
            location: {} as ILocation,
            currentStatus: this.taskStatusForm,
            attachmentsForm: this.formBuilder.array([]),
            isFinished: [false],
        }) as SigncoFormGroup;

        this.resetTaskRule();
        this.resetTaskProperties();

        this.taskForm.patchValue(this.task);

        if (this.task && this.task?.attachments) {
            this.task.attachments.forEach((attachment) => {
                const group = this.formBuilder.group({
                    attachmentId: attachment.id,
                    attachmentImage: attachment.url,
                    attachmentDescription: attachment.description,
                });

                this.attachments.push(group);
            });
        }

        this.applyTaskRule(this.task);
        this.filterTaskStatuses();
        this.patchAssignment(this.task);

        if (this.isCreatingNewTask()) {
            this.generateName();
        }

        if (this.task.location) {
            this.taskForm.get("location").patchValue(this.task.location);
        }

        if (this.task.assignment && this.task.assignment.id) {
            this.taskForm.get("assignmentId").disable();
        }

        this.taskForm.markAsPristine();

        // TODO: There's something fucky going on with angular form bindings when enabling / disabling
        // https://github.com/angular/angular/issues/22556
        setTimeout(() => {
            this.taskForm.enable();

            if (this.task.assignment && this.task.assignment.id) {
                this.taskForm.get("assignmentId")?.disable();
            }
        }, 50);
    }

    private patchPropertyLists(propertyConfiguration: TaskPropertyConfiguration) {
        // Width
        this.widthOptions = this.setPrimitivePropertyConfiguration(
            "width",
            propertyConfiguration.showWidth,
            propertyConfiguration.widths,
            true,
            propertyConfiguration.widthUnit,
        );
        if (!this.widthOptions && propertyConfiguration.showWidth) {
            this.displayCustomWidth = true;
            this.hideWidthList = true;
        }
        // Length
        this.lengthOptions = this.setPrimitivePropertyConfiguration(
            "length",
            propertyConfiguration.showLength,
            propertyConfiguration.lengths,
            true,
            propertyConfiguration.lengthUnit,
        );

        if (!this.lengthOptions && propertyConfiguration.showLength) {
            this.displayCustomLength = true;
            this.hideLengthList = true;
        }
        // Height
        this.heightOptions = this.setPrimitivePropertyConfiguration(
            "height",
            propertyConfiguration.showHeight,
            propertyConfiguration.heights,
            true,
            propertyConfiguration.heightUnit,
        );
        if (!this.heightOptions && propertyConfiguration.showHeight) {
            this.displayCustomHeight = true;
            this.hideHeightList = true;
        }
        // ColoredWidth
        this.coloredWidthOptions = this.setPrimitivePropertyConfiguration(
            "coloredWidth",
            propertyConfiguration.showColoredWidth,
            propertyConfiguration.coloredWidths,
            true,
            propertyConfiguration.coloredWidthUnit,
        );
        if (!this.coloredWidthOptions && propertyConfiguration.showColoredWidth) {
            this.displayCustomColoredWidth = true;
            this.hideColoredWidthList = true;
        }
        this.coloredLengthOptions = this.setPrimitivePropertyConfiguration(
            "coloredLength",
            propertyConfiguration.showColoredLength,
            propertyConfiguration.coloredLenghts,
            true,
            propertyConfiguration.coloredLengthUnit,
        );
        if (!this.coloredLengthOptions && propertyConfiguration.showColoredLength) {
            this.displayCustomColoredLength = true;
            this.hideColoredLengthList = true;
        }
        // Diameter
        this.diameterOptions = this.setPrimitivePropertyConfiguration(
            "diameter",
            propertyConfiguration.showDiameter,
            propertyConfiguration.diameters,
            true,
            propertyConfiguration.diameterUnit,
        );
        if (!this.diameterOptions && propertyConfiguration.showDiameter) {
            this.displayCustomDiameter = true;
            this.hideDiameterList = true;
        }
        // Speed
        this.speedOptions = this.setPrimitivePropertyConfiguration(
            "speed",
            propertyConfiguration.showSpeed,
            propertyConfiguration.speeds,
        );
        if (!this.speedOptions && propertyConfiguration.showSpeed) {
            this.displayCustomSpeed = true;
            this.hideSpeedList = true;
        }
        // Text
        this.textOptions = this.setPrimitivePropertyConfiguration(
            "text",
            propertyConfiguration.showText,
            propertyConfiguration.texts,
        );
        if (!this.textOptions && propertyConfiguration.showText) {
            this.displayCustomText = true;
            this.hideTextList = true;
        } else if (this.textOptions && this.taskStatusForm.get("customText").value) {
            this.displayCustomText = true;
        }
        // Type
        this.typeOptions = this.setMarkingPropertyTypeConfiguration(
            "type",
            propertyConfiguration.showType,
            propertyConfiguration.types,
        );
        // Dimension
        this.dimensionOptions = this.setDimensionPropertyConfiguration(
            "dropdownDimension",
            this.task.currentStatus.dimensionWidth,
            this.task.currentStatus.dimensionLength,
            propertyConfiguration.showDimension,
            propertyConfiguration.dimensions,
            propertyConfiguration.dimensionUnit,
        );
        if (!this.dimensionOptions && propertyConfiguration.showDimension) {
            this.displayCustomDimension = true;
            this.hideDimensionList = true;
        }
        // LElement Dimension
        this.lElementDimensionOptions = this.setDimensionPropertyConfiguration(
            "dropdownLElementDimension",
            this.task.currentStatus.lElementWidth,
            this.task.currentStatus.lElementLength,
            propertyConfiguration.showLElementDimension,
            propertyConfiguration.lElementDimensions,
            propertyConfiguration.lElementUnit,
        );
        if (!this.lElementDimensionOptions && propertyConfiguration.showLElementDimension) {
            this.displayCustomLDimension = true;
            this.hideLElementDimensionList = true;
        }
        // TElement Dimension
        this.tElementDimensionOptions = this.setDimensionPropertyConfiguration(
            "dropdownTElementDimension",
            this.task.currentStatus.tElementWidth,
            this.task.currentStatus.tElementLength,
            propertyConfiguration.showTElementDimension,
            propertyConfiguration.tElementDimensions,
            propertyConfiguration.tElementUnit,
        );
        if (!this.tElementDimensionOptions && propertyConfiguration.showTElementDimension) {
            this.displayCustomTDimension = true;
            this.hideTElementDimensionList = true;
        }
        // SignColorType
        this.signColorOptions = this.setSignColorTypeConfiguration(
            "signColorId",
            propertyConfiguration.showSignColorList,
            propertyConfiguration.signColors,
        );
        if (!this.signColorOptions && this.selectColor && !propertyConfiguration.showSignColorList) {
            this.displayCustomSignColor = true;
            this.setDefaultColor();
        }

        // Block1 width
        this.block1WidthOptions = this.setPrimitivePropertyConfiguration(
            "block1Width",
            propertyConfiguration.showBlock1Width,
            propertyConfiguration.block1Widths,
            true,
            propertyConfiguration.blocksWidthUnit,
        );
        if (!this.block1WidthOptions && propertyConfiguration.showBlock1Width) {
            this.displayCustomBlock1Width = true;
            this.hideBlock1WidthList = true;
        }

        // Block2 width
        this.block2WidthOptions = this.setPrimitivePropertyConfiguration(
            "block2Width",
            propertyConfiguration.showBlock2Width,
            propertyConfiguration.block2Widths,
            true,
            propertyConfiguration.blocksWidthUnit,
        );
        if (!this.block2WidthOptions && propertyConfiguration.showBlock2Width) {
            this.displayCustomBlock2Width = true;
            this.hideBlock2WidthList = true;
        }
    }

    private setMarkingPropertyTypeConfiguration(
        name: string,
        showProperty: boolean,
        defaultValues: MarkingPropertyTypeOption[],
    ): DropdownOption<string>[] {
        if (showProperty && defaultValues && defaultValues.length > 0) {
            const options = this.allMarkingPropertyTypeValues.filter(
                (m) => defaultValues.findIndex((c) => c.value === m.value) > -1,
            );
            if (this.taskStatusForm.get(name).value == null) {
                // set standard value
                const standardOption = defaultValues.find((x) => x.standard === true);
                this.taskStatusForm.get(name).patchValue(standardOption != null ? standardOption.value : null);
            }
            return options;
        }
        return undefined;
    }

    private setSignColorTypeConfiguration(
        name: string,
        showProperty: boolean,
        defaultValues: SignColorOption[],
    ): DropdownOption<string>[] {
        if (showProperty && defaultValues && defaultValues.length > 0) {
            const options = this.allSignColorOptions.filter(
                (m) => defaultValues.findIndex((c) => c.value === m.value) > -1,
            );
            if (this.taskStatusForm.get(name).value == null) {
                // set standard value
                const standardOption = defaultValues.find((x) => x.standard === true);
                this.taskStatusForm.get(name).patchValue(standardOption != null ? standardOption.value : null);
            }
            return options;
        }
        return undefined;
    }

    private setDimensionPropertyConfiguration(
        nameDimension: string,
        width: number,
        length: number,
        showProperty: boolean,
        defaultValues: DimensionOption[],
        unit: Unit = null,
    ): DropdownOption<DimensionOption>[] {
        if (showProperty && defaultValues && defaultValues.length > 0) {
            const unitLabel = this.getUnit(unit);

            const options: DropdownOption<DimensionOption>[] = [];
            options.push({ label: this.translateService.instant("taskPage.other"), value: null });
            options.push(
                ...defaultValues.map((x) => {
                    return {
                        label:
                            x.length +
                            " x " +
                            x.width +
                            (unitLabel !== "" ? " " + unitLabel : "") +
                            (x.description ? " - " + x.description : ""),
                        value: x,
                    } as DropdownOption<DimensionOption>;
                }),
            );
            if (width != null && length != null) {
                const newDimension = {
                    length: length,
                    width: width,
                } as DimensionOption;
                if (!options.find((x) => x.value === newDimension)) {
                    const newOption = {
                        label:
                            newDimension.length +
                            " x " +
                            newDimension.width +
                            (unitLabel !== "" ? " " + unitLabel : "") +
                            (newDimension.description ? " - " + newDimension.description : ""),
                        value: newDimension,
                    } as DropdownOption<DimensionOption>;
                    options.push(newOption);
                    options.sortBy((x) => (x.value ? x.value.length : 0));
                    this.taskStatusForm.get(nameDimension).patchValue(newDimension);
                }
            } else {
                // set standard value
                const standardOption = defaultValues.find((x) => x.standard === true);
                this.taskStatusForm.get(nameDimension).patchValue(standardOption != null ? standardOption : null);
            }
            return options;
        }
        return undefined;
    }

    private setPrimitivePropertyConfiguration(
        name: string,
        showProperty: boolean,
        defaultValues: PrimitiveOption[],
        hasEmptyOption = true,
        unit: Unit = null,
    ): DropdownOption<string>[] {
        if (showProperty && defaultValues && defaultValues.length > 0) {
            const unitLabel = this.getUnit(unit);

            const options: DropdownOption<string>[] = [];
            if (hasEmptyOption) options.push({ label: this.translateService.instant("taskPage.other"), value: null });
            options.push(
                ...defaultValues.map((x) => ({
                    label: x.value.toString() + (unitLabel !== "" ? " " + unitLabel : ""),
                    value: x.value,
                })),
            );
            if (this.taskStatusForm.get(name).value != null) {
                if (!options.find((x) => x.value === this.taskStatusForm.get(name).value)) {
                    const newOption = {
                        label: this.taskStatusForm.get(name).value.toString(),
                        value: this.taskStatusForm.get(name).value,
                    } as DropdownOption<string>;
                    options.push(newOption);
                    options.orderBy((x) => x.value);
                }
            } else {
                // set standard value
                const standardOption = defaultValues.find((x) => x.standard === true);
                this.taskStatusForm.get(name).patchValue(standardOption != null ? standardOption.value : null);
            }
            return options;
        }
        return undefined;
    }

    private patchAssignment(task: ITask) {
        const assignmentId = task.assignment ? task.assignment.id : undefined;
        this.taskForm.controls["assignmentId"].patchValue(assignmentId);
    }

    private patchTaskRuleFormValues(task: ITask) {
        this.taskStatusForm.controls["taskTypeId"].patchValue(task.currentStatus.taskTypeId);
        this.taskStatusForm.controls["signMaterialId"].patchValue(task.currentStatus.signMaterialId);
        this.taskStatusForm.controls["signCategoryId"].patchValue(task.currentStatus.signCategoryId);
        this.taskStatusForm.controls["signCategoryLevel2Id"].patchValue(task.currentStatus.signCategoryLevel2Id);
        this.taskStatusForm.controls["signCategoryLevel3Id"].patchValue(task.currentStatus.signCategoryLevel3Id);
        this.taskStatusForm.controls["signCategoryLevel4Id"].patchValue(task.currentStatus.signCategoryLevel4Id);
        this.taskStatusForm.controls["substrateTypeId"].patchValue(task.currentStatus.substrateTypeId);
        if (this.selectShowSubstrate && task.currentStatus.substrateTypeId == null) {
            this.taskStatusForm.controls["substrateTypeOverride"].enable();
            this.refreshRequiredValidator("substrateTypeOverride", () => true);
        }
    }

    public generateName() {
        const formatString = "yyyyMMDD_HHmmss";
        this.generatedName = moment(new Date()).format(formatString);
        this.taskForm.controls["name"].setValue(this.generatedName);
    }

    public getUnit(unit: Unit) {
        if (!unit) return "";
        const foundUnit = this.allUnits.find((x) => x.value === unit);
        return foundUnit ? foundUnit.label : "";
    }

    //#endregion [Reactive form setup]

    //#region [Task rule controls setup]
    private resetTaskRule() {
        this.currentTaskRule = undefined;
        this.taskRuleIsSet = false;
        this.selectMaterial = false;
        this.selectLevel2Category = false;
        this.selectLevel3Category = false;
        this.selectLevel4Category = false;
        this.selectCanBeRefreshed = false;
        this.selectIsMachineWork = false;
        this.selectCanHavePrimer = false;
        this.selectShowSubstrate = false;
        this.selectShowCategories = false;
        this.selectColor = false;
        this.signMaterials = [];
        this.signCategoryValues = [];
    }

    private filterTaskStatuses() {
        if (this.task && this.taskStatuses && !this.task.id) {
            // when creating new task -> scheduled and OnHold tasks can't be selected
            this.filteredTaskStatuses = this.taskStatuses.filter(
                (x) => x.value === TaskStatus.InProgress || x.value === TaskStatus.Finished,
            );
        } else {
            this.filteredTaskStatuses = this.taskStatuses;
        }
    }

    private applyTaskRule(task: ITask) {
        if (!task.currentStatus.taskTypeId || !this.taskTypes || this.taskTypes.length === 0) {
            this.resetTaskRule();
            return;
        }
        const rule = this.taskRules
            ? this.taskRules.find((r) => r.taskTypeId === task.currentStatus.taskTypeId)
            : undefined;
        if (!rule) {
            this.resetTaskRule();
            return;
        }

        this.refreshTaskRule(rule);

        const currentMaterial = this.getMaterialInfo(task.currentStatus.signMaterialId);
        this.setSelectIsMachineWork(currentMaterial);
        this.setSelectCanHavePrimer(currentMaterial);
        this.setSelectColor(currentMaterial);
        this.setCategoryValues(currentMaterial);
        this.handleCategoryChange(task.currentStatus.signCategoryId, true);
        this.handleCategoryLevel2Change(task.currentStatus.signCategoryLevel2Id, true);
        this.handleCategoryLevel3Change(task.currentStatus.signCategoryLevel3Id, true);
        this.handleCategoryLevel4Change(task.currentStatus.signCategoryLevel4Id, true);

        this.patchTaskRuleFormValues(this.task);
        this.propertyConfiguration =
            this.task.currentStatus.propertyConfiguration == null
                ? new TaskPropertyConfiguration()
                : this.task.currentStatus.propertyConfiguration;
        this.patchPropertyLists(this.propertyConfiguration);
        this.patchCustomValuesOnInit(task);
        this.taskRuleIsSet = true;
    }

    // Set custom values on init if visible and there's no standard list
    private patchCustomValuesOnInit(task: ITask) {
        if (this.displayCustomDiameter && this.hideDiameterList) {
            this.taskStatusForm.controls["customDiameter"].patchValue(task.currentStatus.diameter);
        }
        if (this.displayCustomBlock1Width && this.hideBlock1WidthList) {
            this.taskStatusForm.controls["customBlock1Width"].patchValue(task.currentStatus.block1Width);
        }
        if (this.displayCustomBlock2Width && this.hideBlock2WidthList) {
            this.taskStatusForm.controls["customBlock2Width"].patchValue(task.currentStatus.block2Width);
        }
        if (this.displayCustomHeight && this.hideHeightList) {
            this.taskStatusForm.controls["customHeight"].patchValue(task.currentStatus.height);
        }
        if (this.displayCustomColoredLength && this.hideColoredLengthList) {
            this.taskStatusForm.controls["customColoredLength"].patchValue(task.currentStatus.coloredLength);
        }
        if (this.displayCustomColoredWidth && this.hideColoredWidthList) {
            this.taskStatusForm.controls["customColoredWidth"].patchValue(task.currentStatus.coloredWidth);
        }
        if (this.displayCustomLength && this.hideLengthList) {
            this.taskStatusForm.controls["customLength"].patchValue(task.currentStatus.length);
        }
        if (this.displayCustomWidth && this.hideWidthList) {
            this.taskStatusForm.controls["customWidth"].patchValue(task.currentStatus.width);
        }
        if (this.displayCustomSpeed && this.hideSpeedList) {
            this.taskStatusForm.controls["customSpeed"].patchValue(task.currentStatus.speed);
        }
        if (this.displayCustomText && this.hideTextList) {
            this.taskStatusForm.controls["customText"].patchValue(task.currentStatus.text);
        }
    }

    private refreshTaskRule(rule: ITaskRule) {
        this.currentTaskRule = rule;
        this.setSelectMaterial(rule);
        this.setMaterialValues(rule);
        this.setSelectCanBeRefreshed(rule);
        this.setSelectShowSubstrate(rule);
        this.setSelectShowCategories(rule);
        this.resetCategoryLevels();
        this.setSelectColor(undefined);
        this.setSelectIsMachineWork(undefined);
        this.setSelectCanHavePrimer(undefined);
    }

    private setSelectIsMachineWork(material: ISignMaterialInfo) {
        this.selectIsMachineWork = material && material.canBeMachineWork;
    }

    private setSelectCanHavePrimer(material: ISignMaterialInfo) {
        this.selectCanHavePrimer = material && material.canHavePrimer;
    }

    private setSelectShowCategories(rule: ITaskRule) {
        this.selectShowCategories = rule && rule.showCategories;
    }

    private setSelectShowSubstrate(rule: ITaskRule) {
        this.selectShowSubstrate = rule && rule.showSubstrates;
    }

    private setSelectColor(material: ISignMaterialInfo) {
        this.selectColor = !!material && material.requiresColor;
        this.refreshRequiredValidator(
            "customSignColor",
            () => this.selectColor && this.propertyConfiguration && !this.propertyConfiguration.showSignColorList,
        );
        this.refreshRequiredValidator(
            "signColorId",
            () => this.selectColor && this.propertyConfiguration && this.propertyConfiguration.showSignColorList,
        );
        this.setDefaultColor();
    }

    private setDefaultColor() {
        if (
            this.selectColor &&
            this.propertyConfiguration &&
            !this.propertyConfiguration.showSignColorList &&
            !this.taskStatusForm.controls["customSignColor"].value
        ) {
            this.taskStatusForm.controls["customSignColor"].patchValue(
                this.propertyConfiguration.defaultCustomSignColor
                    ? this.propertyConfiguration.defaultCustomSignColor
                    : this.defaultSignColor,
            );
        }
    }

    private resetCategoryLevels() {
        this.setLevel2Category(undefined);
        this.setLevel3Category(undefined);
        this.setLevel4Category(undefined);
    }

    // Category Levels
    private setLevel2Category(category: ISignCategory, initialCall = false) {
        this.selectLevel2Category = this.canSelectLevelCategory(category);
        this.refreshRequiredValidator("signCategoryLevel2Id", () => this.selectLevel2Category);
        this.signCategoryLevel2Values = this.getCategoryValues(this.selectLevel2Category, category);
        this.taskStatusForm.controls["signCategoryLevel2Id"].patchValue(null);
        if (category != null && !this.selectLevel2Category) {
            this.currentSignCategory = category;
        }
        if (!initialCall) {
            this.applyNewPropertyConfiguration(category, this.selectLevel2Category);
        }
    }

    private setLevel3Category(category: ISignCategory, initialCall = false) {
        this.selectLevel3Category = this.canSelectLevelCategory(category);
        this.refreshRequiredValidator("signCategoryLevel3Id", () => this.selectLevel3Category);
        this.signCategoryLevel3Values = this.getCategoryValues(this.selectLevel3Category, category);
        this.taskStatusForm.controls["signCategoryLevel3Id"].patchValue(null);
        if (category != null && !this.selectLevel3Category) {
            this.currentSignCategory = category;
        }
        if (!initialCall) {
            this.applyNewPropertyConfiguration(category, this.selectLevel3Category);
        }
    }

    private setLevel4Category(category: ISignCategory, initialCall = false) {
        this.selectLevel4Category = this.canSelectLevelCategory(category);
        this.refreshRequiredValidator("signCategoryLevel4Id", () => this.selectLevel4Category);
        this.signCategoryLevel4Values = this.getCategoryValues(this.selectLevel4Category, category);
        this.taskStatusForm.controls["signCategoryLevel4Id"].patchValue(null);
        if (category != null && !this.selectLevel4Category) {
            this.currentSignCategory = category;
        }
        if (!initialCall) {
            this.applyNewPropertyConfiguration(category, this.selectLevel4Category);
        }
    }

    /**
     * Apply configuration based on selected category structure
     * Only if it's a valid category AND there are no sublevels
     */
    private applyNewPropertyConfiguration(category: ISignCategory, hasNextLevel: boolean) {
        if (category != null && !hasNextLevel) {
            this.resetTaskProperties();
            this.propertyConfiguration =
                category.taskPropertyConfiguration == null
                    ? new TaskPropertyConfiguration()
                    : category.taskPropertyConfiguration;
            this.resetValidatorsPropertyConfiguration();
            this.patchPropertyLists(this.propertyConfiguration);
        }
    }

    private canSelectLevelCategory(category: ISignCategory) {
        return !!category && this.allSignCategories.findIndex((cs) => cs.parentId === category.signCategoryId) > -1;
    }

    private getCategoryValues(selectLevelCategory: boolean, category: ISignCategory) {
        if (selectLevelCategory) {
            let filteredSignCategories = [];
            if (this.taskStatusForm.controls["isMachineWork"].value === true) {
                filteredSignCategories = this.allSignCategories.filter(
                    (cs) => cs.parentId === category.signCategoryId && cs.canBeMachineWork,
                );
            } else {
                filteredSignCategories = this.allSignCategories.filter(
                    (cs) => cs.parentId === category.signCategoryId && cs.canBeHandWork,
                );
            }
            filteredSignCategories = filteredSignCategories.sortBy((x) => x.displayOrder);
            const filteredValues = this.allSignCategoryValues.filter(
                (m) => filteredSignCategories.findIndex((c) => c.signCategoryId === m.value) > -1,
            );
            return filteredValues.sort(function (a, b) {
                return (
                    filteredSignCategories.findIndex((item) => item.signCategoryId === a.value) -
                    filteredSignCategories.findIndex((item) => item.signCategoryId === b.value)
                );
            });
        }
    }

    private setSelectCanBeRefreshed(rule: ITaskRule) {
        this.selectCanBeRefreshed = rule && rule.canBeRefreshed;
    }

    private setSelectMaterial(rule: ITaskRule) {
        this.selectMaterial = !!rule && !!rule.signMaterialInfo && rule.signMaterialInfo.length > 0;
    }

    private setMaterialValues(rule: ITaskRule) {
        this.signMaterials =
            rule && rule.signMaterialInfo
                ? this.allSignMaterials.filter(
                      (m) => rule.signMaterialInfo.findIndex((smi) => smi.signMaterialId === m.value) > -1,
                  )
                : [];
    }

    private getMaterialInfo(selectedMaterial: SignMaterial) {
        if (!this.currentTaskRule || !this.currentTaskRule.signMaterialInfo) {
            return;
        }

        return this.currentTaskRule.signMaterialInfo.find((m) => m.signMaterialId === selectedMaterial);
    }

    private setCategoryValues(material: ISignMaterialInfo) {
        let categories = [];
        if (!material) {
            if (this.currentTaskRule.isMarking) {
                categories = this.allSignCategories.filter(
                    (sc) => !sc.parentId && sc.isMarking === this.currentTaskRule.isMarking,
                );
            } else {
                categories = this.allSignCategories.filter(
                    (sc) =>
                        !sc.parentId &&
                        sc.isMarking === this.currentTaskRule.isMarking &&
                        sc.taskTypeId === this.currentTaskRule.taskTypeId,
                );
            }
        } else {
            const isMachineWork =
                material.canBeMachineWork && this.taskStatusForm.controls["isMachineWork"].value === true;

            if (isMachineWork) {
                if (this.currentTaskRule.isMarking) {
                    categories = this.allSignCategories.filter(
                        (sc) => !sc.parentId && sc.canBeMachineWork && sc.isMarking === this.currentTaskRule.isMarking,
                    );
                } else {
                    categories = this.allSignCategories.filter(
                        (sc) =>
                            !sc.parentId &&
                            sc.canBeMachineWork &&
                            sc.isMarking === this.currentTaskRule.isMarking &&
                            sc.taskTypeId === this.currentTaskRule.taskTypeId,
                    );
                }
            } else {
                if (this.currentTaskRule.isMarking) {
                    categories = this.allSignCategories.filter(
                        (sc) => !sc.parentId && sc.canBeHandWork && sc.isMarking === this.currentTaskRule.isMarking,
                    );
                } else {
                    categories = this.allSignCategories.filter(
                        (sc) =>
                            !sc.parentId &&
                            sc.canBeHandWork &&
                            sc.isMarking === this.currentTaskRule.isMarking &&
                            sc.taskTypeId === this.currentTaskRule.taskTypeId,
                    );
                }
            }
        }
        if (categories) {
            categories = categories.sortBy((x) => x.displayOrder);
            const filteredValues = this.allSignCategoryValues.filter(
                (m) => categories.findIndex((c) => c.signCategoryId === m.value) > -1,
            );
            this.signCategoryValues = filteredValues.sort(function (a, b) {
                return (
                    categories.findIndex((item) => item.signCategoryId === a.value) -
                    categories.findIndex((item) => item.signCategoryId === b.value)
                );
            });
        } else {
            this.signCategoryValues = [];
        }
    }

    private resetTaskProperties() {
        this.resetFormControlValues(
            "note",
            "customSignColor",
            "signColorId",
            "markingsCount",
            "hoursCount",
            "minutesCount",
            "length",
            "customLength",
            "width",
            "customWidth",
            "coloredLength",
            "coloredWidth",
            "customColoredWidth",
            "dropdownDimension",
            "customDimensionLength",
            "customDimensionWidth",
            "customDimensionDescription",
            "type",
            "bannerCount",
            "logoCount",
            "diameter",
            "customDiameter",
            "customLElementWidth",
            "customLElementLength",
            "dropdownLElementDimension",
            "tElementCount",
            "lElementCount",
            "tramElementCount",
            "height",
            "customHeight",
            "text",
            "customText",
            "speed",
            "customSpeed",
            "placenumber",
            "block1Amount",
            "block1Width",
            "customBlock1Width",
            "block2Amount",
            "block2Width",
            "customBlock2Width",
            "lengthA",
            "lengthB",
            "lengthC",
            "lengthD",
            "whiteSubstrateLength",
            "whiteSubstrateWidth",
            "whiteBandCount",
            "redBandCount",
            "yellowBandCount",
            "orangeBandCount",
            "greenBandCount",
            "blueBandCount",
            "purpleBandCount",
            "bendCount",
            "arrowHeadCount",
            "roundedEndCount",
            "shortPieceCount",
        );
        this.displayCustomLength = false;
        this.hideLengthList = false;
        this.displayCustomWidth = false;
        this.hideWidthList = false;
        this.displayCustomColoredWidth = false;
        this.hideColoredWidthList = false;
        this.displayCustomColoredLength = false;
        this.hideColoredLengthList = true;
        this.displayCustomDimension = false;
        this.hideDimensionList = false;
        this.displayCustomDiameter = false;
        this.displayCustomBlock1Width = false;
        this.displayCustomBlock2Width = false;
        this.hideDiameterList = false;
        this.hideBlock1WidthList = false;
        this.hideBlock2WidthList = false;
        this.displayCustomLDimension = false;
        this.hideLElementDimensionList = false;
        this.displayCustomTDimension = false;
        this.hideTElementDimensionList = false;
        this.displayCustomHeight = false;
        this.hideHeightList = false;
        this.displayCustomText = false;
        this.hideTextList = false;
        this.displayCustomSpeed = false;
        this.displayCustomSignColor = false;
        this.hideSpeedList = false;
    }

    private resetValidatorsPropertyConfiguration() {
        this.refreshRequiredValidator(
            "customSignColor",
            () => this.selectColor && this.propertyConfiguration && !this.propertyConfiguration.showSignColorList,
        );
        this.refreshRequiredValidator(
            "signColorId",
            () => this.selectColor && this.propertyConfiguration && this.propertyConfiguration.showSignColorList,
        );
    }

    initializeWorkers(): void {
        const searchParameters = new SearchParameters();
        searchParameters.sort = [];
        searchParameters.sort.push(new SortDescriptor(SortDirection.ascending, "FirstName"));

        this.workerApi
            .getAll$(searchParameters)
            .pipe(
                map((workers: IWorker[], _index: number) => {
                    return workers.map((worker) => {
                        return {
                            label: `${worker.firstName} ${worker.lastName}`,
                            value: worker.id,
                        } as DropdownOption<number>;
                    });
                }),
            )
            .subscribe({
                next: (workers) => {
                    this.workers = workers.map((worker) => {
                        return { label: worker.label, value: worker.value } as DropdownOption<number>;
                    });
                },
                error: (_error) => {
                    this.workers = [];
                },
            });
    }

    //#endregion [Task rule controls setup]

    //#region [Server calls]
    async save() {
        const isValid = await this.formValidationService.checkValidity(this.taskForm);

        if (!isValid) return;
        if (this.isCreatingNewTask()) {
            this.createNew();
        } else {
            this.update();
        }
    }

    async saveAndCopy() {
        const isValid = await this.formValidationService.checkValidity(this.taskForm);

        if (!isValid) return;
        if (this.isCreatingNewTask()) {
            this.createNew(true);
        } else {
            this.update(true);
        }
    }

    isCreatingNewTask(): boolean {
        const taskId = this.route.snapshot.params["taskId"];
        return taskId && taskId === "new";
    }

    private async createNew(makeCopy = false) {
        const taskCreator = Object.assign(new TaskCreator(), this.taskForm.getRawValue()) as TaskCreator;
        taskCreator.currentStatus.isVerified = false;
        taskCreator.currentStatus.taskStatusId = (this.taskForm.get("isFinished").value as boolean)
            ? TaskStatus.Finished
            : TaskStatus.InProgress;
        taskCreator.start = new Date();
        taskCreator.end = taskCreator?.currentStatus.taskStatusId === TaskStatus.Finished ? new Date() : null;

        taskCreator.location = {
            coordinate: this.task.location.coordinate,
        } as LocationCreator;

        if (
            this.taskStatusForm.get("workerIds")?.value &&
            (<number[]>this.taskStatusForm.get("workerIds").value)?.length > 0
        ) {
            taskCreator.currentStatus.workerTasks = (<number[]>this.taskStatusForm.get("workerIds").value).map(
                (workerId: number) => {
                    return {
                        workerId: workerId,
                    } as WorkerTaskCreator;
                },
            );
        } else {
            taskCreator.currentStatus.workerTasks = null;
        }
        this.setCustomValuesOnTask(taskCreator);

        const createdTask = await firstValueFrom(this.taskApi.create$(taskCreator));
        this.toastService.showSuccess(this.translateService.instant("taskPage.taskCreated"));

        if (makeCopy) {
            taskCreator.name = moment().format("YYYYMMDD_HHmmss");
            taskCreator.currentStatus.taskStatusId = TaskStatus.InProgress;
            const copyTask = await firstValueFrom(this.taskApi.create$(taskCreator));
            this.toastService.showSuccess(this.translateService.instant("taskPage.taskCreated"));
            await this.router.navigate([
                "/assignment",
                this.assignmentService.selectedPlannedAssignment.id,
                "task",
                copyTask.id,
            ]);
        } else {
            await this.router.navigate([
                "/assignment",
                this.assignmentService.selectedPlannedAssignment.id,
                "task",
                createdTask.id,
            ]);
        }
    }

    private update(makeCopy = false) {
        const onSuccess = async (savedTask: ITask) => {
            this.onSaveSuccess(savedTask);
            if (makeCopy) {
                const taskCreator = Object.assign(new TaskCreator(), savedTask) as TaskCreator;
                taskCreator.assignmentId = savedTask.assignment.id;
                taskCreator.name = moment().format("YYYYMMDD_HHmmss");
                taskCreator.currentStatus.taskStatusId = TaskStatus.InProgress;
                this.taskApi.create$(taskCreator).subscribe(onSuccessCopy, this.onSaveError.bind(this));
            }
            this.toastService.showSuccess(this.translateService.instant("taskPage.taskUpdated"));
        };
        const onSuccessCopy = async (newTask: ITask) => {
            this.toastService.showSuccess(this.translateService.instant("taskPage.taskCopied"));
            this.router.navigate([
                "/assignment",
                this.assignmentService.selectedPlannedAssignment.id,
                "task",
                newTask.id,
            ]);
        };

        const updatedTask = Object.assign(new TaskUpdater(), this.task, this.taskForm.getRawValue()) as TaskUpdater;
        updatedTask.locationId = this.task.location.id;

        const isFinished = this.taskForm.get("isFinished").value as boolean;
        if (isFinished) {
            updatedTask.currentStatus.taskStatusId = TaskStatus.Finished;
        } else {
            updatedTask.currentStatus.taskStatusId = TaskStatus.InProgress;
        }

        if (
            this.taskStatusForm.get("workerIds")?.value &&
            (<number[]>this.taskStatusForm.get("workerIds").value)?.length > 0
        ) {
            updatedTask.currentStatus.workerTasks = (<number[]>this.taskStatusForm.get("workerIds").value).map(
                (workerId: number) => {
                    return {
                        workerId: workerId,
                    } as WorkerTaskCreator;
                },
            );
        } else {
            updatedTask.currentStatus.workerTasks = null;
        }

        this.setCustomValuesOnTask(updatedTask);

        this.submitting = true;

        this.taskApi.update$(updatedTask).subscribe(onSuccess, this.onSaveError.bind(this));
    }

    public setCustomValuesOnTask(creator: TaskCreator) {
        creator.currentStatus.isNew = this.setSelectCanBeRefreshed ? creator.currentStatus.isNew : true;
        creator.currentStatus.propertyConfiguration = this.propertyConfiguration;
        creator.currentStatus.customSignColor =
            this.selectColor && !this.propertyConfiguration.showSignColorList
                ? creator.currentStatus.customSignColor
                : undefined;
        creator.currentStatus.signColorId =
            this.selectColor && this.propertyConfiguration.showSignColorList
                ? creator.currentStatus.signColorId
                : undefined;
        creator.currentStatus.width = this.propertyConfiguration.showWidth
            ? this.displayCustomWidth
                ? this.taskStatusForm.get("customWidth").value
                : creator.currentStatus.width
            : undefined;
        creator.currentStatus.widthUnitId = this.propertyConfiguration.showWidth
            ? this.propertyConfiguration.widthUnit
            : undefined;
        creator.currentStatus.length = this.propertyConfiguration.showLength
            ? this.displayCustomLength
                ? this.taskStatusForm.get("customLength").value
                : creator.currentStatus.length
            : undefined;
        creator.currentStatus.lengthUnitId = this.propertyConfiguration.showLength
            ? this.propertyConfiguration.lengthUnit
            : undefined;
        creator.currentStatus.height = this.propertyConfiguration.showHeight
            ? this.displayCustomHeight
                ? this.taskStatusForm.get("customHeight").value
                : creator.currentStatus.height
            : undefined;
        creator.currentStatus.heightUnitId = this.propertyConfiguration.showHeight
            ? this.propertyConfiguration.heightUnit
            : undefined;
        creator.currentStatus.coloredWidth = this.propertyConfiguration.showColoredWidth
            ? this.displayCustomColoredWidth
                ? this.taskStatusForm.get("customColoredWidth").value
                : creator.currentStatus.coloredWidth
            : undefined;
        creator.currentStatus.coloredWidthUnit = this.propertyConfiguration.showColoredWidth
            ? this.propertyConfiguration.coloredWidthUnit
            : undefined;
        creator.currentStatus.coloredLength = this.propertyConfiguration.showColoredLength
            ? this.displayCustomColoredLength
                ? this.taskStatusForm.get("customColoredLength").value
                : creator.currentStatus.coloredLength
            : undefined;
        creator.currentStatus.coloredLengthUnit = this.propertyConfiguration.showColoredLength
            ? this.propertyConfiguration.coloredLengthUnit
            : undefined;
        creator.currentStatus.whiteSubstrateLengthUnitId = this.propertyConfiguration.showWhiteSubstrateLength
            ? this.propertyConfiguration.whiteSubstrateLengthUnit
            : undefined;
        creator.currentStatus.whiteSubstrateWidthUnitId = this.propertyConfiguration.showWhiteSubstrateWidth
            ? this.propertyConfiguration.whiteSubstrateWidthUnit
            : undefined;

        creator.currentStatus.diameter = this.propertyConfiguration.showDiameter
            ? this.displayCustomDiameter
                ? this.taskStatusForm.get("customDiameter").value
                : creator.currentStatus.diameter
            : undefined;
        creator.currentStatus.diameterUnitId = this.propertyConfiguration.showDiameter
            ? this.propertyConfiguration.diameterUnit
            : undefined;
        creator.currentStatus.block1Width = this.propertyConfiguration.showBlock1Width
            ? this.displayCustomBlock1Width
                ? this.taskStatusForm.get("customBlock1Width").value
                : creator.currentStatus.block1Width
            : undefined;
        creator.currentStatus.block2Width = this.propertyConfiguration.showBlock2Width
            ? this.displayCustomBlock2Width
                ? this.taskStatusForm.get("customBlock2Width").value
                : creator.currentStatus.block2Width
            : undefined;
        creator.currentStatus.blocksWidthUnitId =
            this.propertyConfiguration.showBlock1Amount || this.propertyConfiguration.showBlock2Amount
                ? this.propertyConfiguration.blocksWidthUnit
                : undefined;
        creator.currentStatus.speed = this.propertyConfiguration.showSpeed
            ? this.displayCustomSpeed
                ? this.taskStatusForm.get("customSpeed").value
                : creator.currentStatus.speed
            : undefined;
        creator.currentStatus.text = this.propertyConfiguration.showText
            ? this.displayCustomText
                ? this.taskStatusForm.get("customText").value
                : creator.currentStatus.text
            : undefined;
        creator.currentStatus.type = this.propertyConfiguration.showType ? creator.currentStatus.type : undefined;
        creator.currentStatus.dimensionLength = this.propertyConfiguration.showDimension
            ? this.displayCustomDimension
                ? this.taskStatusForm.get("customDimensionLength").value
                : this.taskStatusForm.get("dropdownDimension").value.length
            : undefined;
        creator.currentStatus.dimensionWidth = this.propertyConfiguration.showDimension
            ? this.displayCustomDimension
                ? this.taskStatusForm.get("customDimensionWidth").value
                : this.taskStatusForm.get("dropdownDimension").value.width
            : undefined;
        creator.currentStatus.dimensionDescription = this.propertyConfiguration.showDimension
            ? this.displayCustomDimension
                ? this.taskStatusForm.get("customDimensionDescription").value
                : this.taskStatusForm.get("dropdownDimension").value.description
            : undefined;
        creator.currentStatus.dimensionUnitId = this.propertyConfiguration.showDimension
            ? this.propertyConfiguration.dimensionUnit
            : undefined;
        creator.currentStatus.lElementWidth = this.propertyConfiguration.showLElementDimension
            ? this.displayCustomLDimension
                ? this.taskStatusForm.get("customLElementWidth").value
                : this.taskStatusForm.get("dropdownLElementDimension").value.width
            : undefined;
        creator.currentStatus.lElementLength = this.propertyConfiguration.showLElementDimension
            ? this.displayCustomLDimension
                ? this.taskStatusForm.get("customLElementLength").value
                : this.taskStatusForm.get("dropdownLElementDimension").value.length
            : undefined;
        creator.currentStatus.lElementUnitId = this.propertyConfiguration.showLElementDimension
            ? this.propertyConfiguration.lElementUnit
            : undefined;
        creator.currentStatus.tElementLength = this.propertyConfiguration.showTElementDimension
            ? this.displayCustomTDimension
                ? this.taskStatusForm.get("customTElementLength").value
                : this.taskStatusForm.get("dropdownTElementDimension").value.length
            : undefined;
        creator.currentStatus.tElementWidth = this.propertyConfiguration.showTElementDimension
            ? this.displayCustomTDimension
                ? this.taskStatusForm.get("customTElementWidth").value
                : this.taskStatusForm.get("dropdownTElementDimension").value.width
            : undefined;
        creator.currentStatus.tElementUnitId = this.propertyConfiguration.showTElementDimension
            ? this.propertyConfiguration.tElementUnit
            : undefined;
    }

    onSaveSuccess(savedTask: ITask) {
        this.task = savedTask;
        this.taskForm.markAsPristine();
        this.submitting = false;
    }

    onSaveError() {
        this.submitting = false;
    }

    private async initDropdowns() {
        const [
            taskRules,
            taskTypes,
            signMaterials,
            signCategoriesResult,
            signCategoryValues,
            substrateTypes,
            markingPropertyTypeValues,
            signColorOptions,
            units,
            taskStatuses,
        ] = await Promise.all([
            firstValueFrom(this.taskRulesApi.getAll$()),
            this.fillTaskTypeDropdown(this.translateService.currentLang),
            this.fillTaskSignMaterialDropdown(this.translateService.currentLang),
            firstValueFrom(this.signCategoryApi.search$()),
            this.fillTaskSignCategoryDropdown(this.translateService.currentLang),
            this.fillTaskSubstrateTypes(this.translateService.currentLang),
            this.fillMarkingPropertyTypeDropdown(this.translateService.currentLang),
            this.fillSignColorTypeDropdown(this.translateService.currentLang),
            this.fillUnitDropdown(this.translateService.currentLang),
            this.fillTaskStatusesDropdown(this.translateService.currentLang),
        ]);

        this.taskRules = taskRules;
        this.taskTypes = taskTypes;
        this.allSignMaterials = signMaterials;
        this.allSignCategories = signCategoriesResult.data;
        this.allSignCategoryValues = signCategoryValues;
        this.allSubstrateTypes = substrateTypes;
        this.allMarkingPropertyTypeValues = markingPropertyTypeValues;
        this.allSignColorOptions = signColorOptions;
        this.allUnits = units;
        this.taskStatuses = taskStatuses;

        this.initializeWorkers();
    }

    //region Fill dropdowns

    private async fillUnitDropdown(language = "en"): Promise<DropdownOption<string>[]> {
        const units = this.domainDataService.domainData.enums["unit"].values;

        const dropDownValues: DropdownOption<string>[] = [];
        Object.entries(units).forEach(([key, value]) => {
            dropDownValues.push({
                label: value.translations[language],
                value: key,
            });
        });
        return dropDownValues;
    }

    private async fillSignColorTypeDropdown(language = "en"): Promise<DropdownOption<string>[]> {
        const signColorTypes = this.domainDataService.domainData.enums["signColorType"].values;

        const dropDownValues: DropdownOption<string>[] = [];
        Object.entries(signColorTypes).forEach(([key, value]) => {
            dropDownValues.push({
                label: value.translations[language],
                value: key,
            });
        });
        return dropDownValues;
    }

    private async fillMarkingPropertyTypeDropdown(language = "en"): Promise<DropdownOption<string>[]> {
        const markingPropertyTypes = this.domainDataService.domainData.enums["markingPropertyType"].values;

        const dropDownValues: DropdownOption<string>[] = [];
        Object.entries(markingPropertyTypes).forEach(([key, value]) => {
            dropDownValues.push({
                label: value.translations[language],
                value: key,
            });
        });
        return dropDownValues;
    }

    private async fillTaskTypeDropdown(language = "en"): Promise<DropdownOption<string>[]> {
        const taskTypes = this.domainDataService.domainData.enums["taskType"].values;

        const dropDownValues: DropdownOption<string>[] = [];
        Object.entries(taskTypes).forEach(([key, value]) => {
            dropDownValues.push({
                label: value.translations[language],
                value: key,
            });
        });
        return dropDownValues;
    }

    private async fillTaskSignMaterialDropdown(language = "en"): Promise<DropdownOption<string>[]> {
        const signMaterials = this.domainDataService.domainData.enums["signMaterial"].values;

        const dropDownValues: DropdownOption<string>[] = [];
        Object.entries(signMaterials).forEach(([key, value]) => {
            dropDownValues.push({
                label: value.translations[language],
                value: key,
            });
        });
        return dropDownValues;
    }

    private async fillTaskStatusesDropdown(language = "en"): Promise<DropdownOption<string>[]> {
        const taskStatuses = this.domainDataService.domainData.enums["taskStatus"].values;

        const dropDownValues: DropdownOption<string>[] = [];
        Object.entries(taskStatuses).forEach(([key, value]) => {
            dropDownValues.push({
                label: value.translations[language],
                value: key,
            });
        });
        return dropDownValues;
    }

    private async fillTaskSignCategoryDropdown(language = "en"): Promise<DropdownOption<string>[]> {
        const signCategories = this.domainDataService.domainData.enums["signCategoryValue"].values;

        const dropDownValues: DropdownOption<string>[] = [];
        Object.entries(signCategories).forEach(([key, value]) => {
            dropDownValues.push({
                label: value.translations[language],
                value: key,
            });
        });
        return dropDownValues;
    }

    private async fillTaskSubstrateTypes(language = "en"): Promise<DropdownOption<string>[]> {
        const substrateTypes = this.domainDataService.domainData.enums["substrateType"].values;

        const dropDownValues: DropdownOption<string>[] = [];
        Object.entries(substrateTypes).forEach(([key, value]) => {
            dropDownValues.push({
                label: value.translations[language],
                value: key,
            });
        });
        dropDownValues.push({
            label: this.translateService.instant("taskPage.other"),
            value: null,
        });

        return dropDownValues;
    }

    //endregion

    //#endregion [Server calls]

    //#region [Helpers]
    private resetFormControlValues(...formControlNames: string[]) {
        formControlNames.forEach((controlName) => {
            if (!this.taskForm.controls[controlName]) {
                if (!this.taskStatusForm.controls[controlName]) {
                    return;
                }

                this.taskStatusForm.controls[controlName].setValue(null);
                return;
            }
            this.taskForm.controls[controlName].setValue(null);
        });
    }

    private refreshRequiredValidator(controlName: string, condition: () => boolean) {
        if (condition()) {
            if (this.taskForm.controls[controlName]) {
                this.taskForm.controls[controlName].setValidators([Validators.required]);
            } else {
                this.taskStatusForm.controls[controlName].setValidators([Validators.required]);
            }
        } else {
            if (this.taskForm.controls[controlName]) {
                this.taskForm.controls[controlName].clearValidators();
            } else {
                this.taskStatusForm.controls[controlName].clearValidators();
            }
        }
        if (this.taskForm.controls[controlName]) {
            this.taskForm.controls[controlName].updateValueAndValidity();
        } else {
            this.taskStatusForm.controls[controlName].updateValueAndValidity();
        }
    }

    //#endregion [Helpers]

    get location(): ILocation {
        return this.taskForm.get("location").value;
    }

    getAddress(address: IAddress) {
        if (this.location?.address) {
            return this.locationService.convertToAddressString(address);
        }
        return "";
    }

    @ViewChild(MapsDialogComponent) mapsDialog!: MapsDialogComponent;

    openMapsDialog(event: Event) {
        this.mapsDialog.open(event);
    }

    async onSaveModal(coordinates: ICoordinate) {
        const addressWithTimeZone = await firstValueFrom(this.locationApi.getAddressFromCoordinates$(coordinates));
        const location = {
            address: addressWithTimeZone.address,
            coordinate: coordinates,
        };
        this.taskForm.get("location").patchValue(location);

        if (!this.isCreatingNewTask()) {
            const locationUpdater = new LocationUpdater({
                ...this.task.location,
                coordinate: location.coordinate,
                address: location.address,
            });
            await firstValueFrom(this.locationApi.update$(locationUpdater));
        }
    }

    //region Attachment
    get attachments(): FormArray<FormGroup> {
        return this.taskForm.get("attachmentsForm") as unknown as FormArray<FormGroup>;
    }

    async onParkingBanAttachmentSubmit(image: string) {
        if (!this.task?.name) {
            this.task = await this.taskService.fetchTask(this.route.snapshot.params["taskId"]);
        }
        if (!this.isCreatingNewTask()) {
            const coordinates = await this.locationService.getCurrentCoordinates();
            const addressWithTimeZone = await this.locationService.getAddressFromCoordinates(coordinates);

            const imageName = this.task.name;
            const now = moment().format("YYYYMMDD_HHmmss");

            const location = {
                coordinate: coordinates,
                address: addressWithTimeZone.address,
                code: `${imageName}_${now}`,
            } as ILocation;

            const attachment = await this.taskService.uploadTaskAttachment(imageName, this.task.id, image, location);
            this.task = await this.taskService.fetchTask(this.task.id);

            const formGroup = this.formBuilder.group({
                attachmentId: attachment.id,
                attachmentImage: image,
                attachmentDescription: "",
            });

            this.attachments.push(formGroup);
        }
    }

    async onParkingBanAttachmentDelete(attachmentId: number) {
        if (!this.isCreatingNewTask()) {
            await this.taskService.deleteTaskAttachment(attachmentId);

            this.task = await this.taskService.fetchTask(this.task.id);
            this.attachments.removeAt(
                this.attachments.controls.findIndex((control) => control.get("attachmentId").value === attachmentId),
            );
        }
    }

    async onParkingBanAttachmentDescriptionChange(event: Event, photoForm: FormGroup) {
        const attachmentId = photoForm.controls["attachmentId"].value;
        const attachmentDescription = photoForm.controls["attachmentDescription"].value;

        const attachment = this.task.attachments.find((a) => a.id === attachmentId);
        attachment.description = attachmentDescription;

        await this.taskService.updateTaskAttachment(attachment, this.task.id);
    }

    //endregion

    async addPhoto() {
        const cameraInput = document.getElementById("cameraInput") as HTMLInputElement;
        if (!cameraInput) this.toastService.showError("Camera is not working, please try again later.");
        if (!this.isCreatingNewTask()) {
            cameraInput.click();
            return;
        }
        const isValid = await this.formValidationService.checkValidity(this.taskForm);

        if (!isValid) {
            this.toastService.showWarning(this.translateService.instant("taskPage.invalidForm"));
            return;
        }
        await this.createNew();
        cameraInput.click();
    }

    cameraInputChanged(event: any) {
        const cameraInput = document.getElementById("cameraInput") as HTMLInputElement;
        if (event.target.files && event.target.files.length === 1) {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = async (fileLoadend) => {
                await this.onParkingBanAttachmentSubmit(fileLoadend.target.result as string);
                cameraInput.value = null;
            };
        }
    }
}
