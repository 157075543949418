import { Injectable, signal } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class TitleService {
    goBack = signal<string[]>(undefined);
    title = "Markings as a Service";

    refreshClicked$ = new Subject<void>();
}
