import { Component, ViewChild } from "@angular/core";
import { ModalComponent } from "@ramudden/ui";
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";

@Component({
    selector: "app-pdf-viewer-dialog",
    standalone: true,
    templateUrl: "./pdf-viewer-dialog.component.html",
    styleUrl: "./pdf-viewer-dialog.component.scss",
    imports: [ModalComponent, NgxExtendedPdfViewerModule],
})
export class PdfViewerDialogComponent {
    @ViewChild("modal") modal: ModalComponent;

    title: string;
    pdfUrl: string;
    zoom = 1.0;

    openModal(title: string, pdfUrl: string, event: Event) {
        this.title = title;
        this.pdfUrl = pdfUrl;

        this.modal.openModal(event);
    }
}
