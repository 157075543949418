import { Component, Input, output, ViewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { ICoordinate } from "@ramudden/data-access/models/location";
import { ModalComponent, SvgComponent } from "@ramudden/ui";
import { MapComponent } from "../../../../../../components/map/map.component";

@Component({
    selector: "app-maps-dialog",
    standalone: true,
    templateUrl: "./maps-dialog.component.html",
    imports: [MapComponent, ModalComponent, SvgComponent, TranslateModule],
})
export class MapsDialogComponent {
    @Input({ required: true }) coordinates: ICoordinate;
    saveModal = output<ICoordinate>();

    @ViewChild(MapComponent) googleMap!: MapComponent;
    @ViewChild(ModalComponent) modal!: ModalComponent;

    marker: google.maps.marker.AdvancedMarkerElement;

    open(event?: Event) {
        this.modal.openModal(event);
    }

    async onMapLoaded() {
        if (!this.googleMap.map || this.marker) return;

        this.marker = await this.googleMap.createMarker(this.googleMap.coordinates, "", true);
        this.marker.addListener("dragend", () => {
            this.onCoordinatesChanged({
                latitude: this.marker.position.lat,
                longitude: this.marker.position.lng,
            } as ICoordinate);
        });
        this.googleMap.map.addListener("click", (event: google.maps.MapMouseEvent) => {
            // TODO: Add a check if user really wants to do it
            const coordinates = {
                latitude: event.latLng.lat(),
                longitude: event.latLng.lng(),
            };
            this.marker.position = event.latLng;
            this.onCoordinatesChanged(coordinates);
        });
    }

    async onCoordinatesChanged(coordinate: ICoordinate) {
        this.coordinates = coordinate;
    }

    onSave() {
        this.saveModal.emit(this.coordinates);
    }

    useCurrentCoordinates(coordinates: ICoordinate) {
        this.coordinates = coordinates;
        this.marker.position = new google.maps.LatLng(coordinates.latitude, coordinates.longitude);
    }
}
