import { Component, ViewChild } from "@angular/core";
import { ModalComponent } from "@ramudden/ui";

@Component({
    selector: "app-image-viewer-dialog",
    standalone: true,
    templateUrl: "./image-viewer-dialog.component.html",
    imports: [ModalComponent],
})
export class ImageViewerDialogComponent {
    @ViewChild("modal") modal: ModalComponent;

    title: string;
    imageUrl: string;

    openModal(title: string, imageUrl: string, event: Event) {
        this.title = title;
        this.imageUrl = imageUrl;

        this.modal.openModal(event);
    }
}
