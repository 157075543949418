import { AsyncPipe, NgIf } from "@angular/common";
import { Component, inject, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { LoaderService } from "../../services/loader.service";
import { LoaderIconComponent } from "../loader-icon/loader-icon.component";

@Component({
    selector: "m-loader",
    standalone: true,
    templateUrl: "./loader.component.html",
    styleUrl: "./loader.component.scss",
    imports: [AsyncPipe, NgIf, LoaderIconComponent],
})
export class LoaderComponent implements OnInit {
    isLoading: Observable<boolean>;
    private readonly loaderService = inject(LoaderService);

    ngOnInit() {
        this.isLoading = this.loaderService.loading$;
    }
}
