import { DatePipe } from "@angular/common";
import { Component, inject, OnInit, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { IAssignment } from "@ramudden/data-access/models/assignment";
import { ICoordinate, ILocation, LocationUpdater } from "@ramudden/data-access/models/location";
import { IParkingBan } from "@ramudden/data-access/models/parking-ban";
import { IPlannedAssignment } from "@ramudden/data-access/models/planned-event";
import { LocationApi } from "@ramudden/data-access/resource/location.api";
import { SvgComponent, ToastService } from "@ramudden/ui";
import moment from "moment/moment";
import { firstValueFrom } from "rxjs";
import { QrScannerComponent } from "../../../../components/qr-scanner/qr-scanner.component";
import { IComponentCanDeactivate } from "../../../../guards/pending-changes.guard";
import { DefaultComponent } from "../../../../layout/content/default/default.component";
import { AssignmentService } from "../../../../services/assignment.service";
import { ParkingBanService } from "../../../../services/parking-ban.service";
import { TitleService } from "../../../../services/title.service";
import { MapsDialogComponent } from "./dialogs/maps/maps-dialog.component";

@Component({
    selector: "app-edit-parking-ban",
    standalone: true,
    imports: [
        DefaultComponent,
        ReactiveFormsModule,
        SvgComponent,
        MapsDialogComponent,
        DatePipe,
        QrScannerComponent,
        TranslateModule,
    ],
    templateUrl: "./edit-parking-ban.component.html",
})
export class EditParkingBanComponent implements OnInit, IComponentCanDeactivate {
    protected readonly now = Date;

    @ViewChild(MapsDialogComponent) mapsDialog!: MapsDialogComponent;

    private readonly toastService = inject(ToastService);
    private readonly formBuilder = inject(FormBuilder);
    private readonly parkingBanService = inject(ParkingBanService);
    private readonly route = inject(ActivatedRoute);
    private readonly router = inject(Router);
    private readonly titleService = inject(TitleService);
    private readonly locationApi = inject(LocationApi);
    private readonly assignmentService = inject(AssignmentService);

    plannedAssignment: IPlannedAssignment;
    assignment: IAssignment;
    parkingBan: IParkingBan;
    parkingBanForm: FormGroup;

    //region Implements
    async ngOnInit() {
        const plannedAssignmentId = this.route.snapshot.params["plannedAssignmentId"];
        const parkingBanId = this.route.snapshot.params["parkingBanId"];

        await this.fetchPlannedAssignment(plannedAssignmentId);
        await this.fetchParkingBan(plannedAssignmentId, parkingBanId);
        this.titleService.title = "Parkeerverbod";
        this.titleService.goBack.set(["/assignment", plannedAssignmentId, "parking-bans"]);

        this.createForm();
    }

    async canDeactivate() {
        const updatedParkingBan = {
            assignment: this.parkingBan.assignment,
            description: this.parkingBan.description,
            end: new Date(this.parkingBan.end),
            id: this.parkingBan.id,
            isCompleted: this.parkingBan.isCompleted,
            location: this.parkingBan.location,
            name: this.parkingBan.name,
            start: new Date(this.parkingBan.start),
        } as IParkingBan;

        updatedParkingBan.description = this.parkingBanForm.get("generalForm").get("description").value;
        updatedParkingBan.end = moment(this.parkingBanForm.get("generalForm").get("end").value).toDate();
        updatedParkingBan.isCompleted = this.parkingBanForm.get("isCompleted").value;
        updatedParkingBan.start = moment(this.parkingBanForm.get("generalForm").get("start").value).toDate();

        await this.parkingBanService.updateParkingBan(updatedParkingBan);

        for (const control of this.attachments.controls) {
            const attachmentId = control.get("attachmentId").value;
            const attachmentDescription = control.get("attachmentDescription").value;

            const currentAttachment = this.parkingBan.photos.find((photo) => photo.id === attachmentId);
            if (currentAttachment.description !== attachmentDescription) {
                currentAttachment.description = attachmentDescription;
                await this.parkingBanService.updateParkingBanAttachment(currentAttachment, this.parkingBan.id);
            }
        }

        for (const control of this.exceptions.controls) {
            const exceptionId = control.get("exceptionId").value;
            const exceptionLicensePlate = control.get("exceptionLicensePlate").value;

            const currentException = this.parkingBan.exceptions.find((exception) => exception.id === exceptionId);
            if (currentException.licensePlate !== exceptionLicensePlate) {
                currentException.licensePlate = exceptionLicensePlate;
                await this.parkingBanService.updateParkingBanException(currentException);
            }
        }

        for (const control of this.signScans.controls) {
            const signScanId = control.get("signScanId").value;
            const signScanCode = control.get("signScanCode").value;

            const currentSignScan = this.parkingBan.signScans.find((signScan) => signScan.id === signScanId);
            if (currentSignScan.signCode !== signScanCode) {
                currentSignScan.signCode = signScanCode;
                await this.parkingBanService.updateParkingBanSignScan(currentSignScan);
            }
        }

        return true;
    }
    //endregion

    //region Getters
    get attachments(): FormArray<FormGroup> {
        return this.parkingBanForm.get("attachmentsForm") as FormArray;
    }

    get exceptions(): FormArray<FormGroup> {
        return this.parkingBanForm.get("exceptionsForm") as FormArray;
    }

    get signScans(): FormArray<FormGroup> {
        return this.parkingBanForm.get("signScansForm") as FormArray;
    }

    get location(): ILocation {
        return this.parkingBanForm.get("generalForm").get("location").value;
    }
    //endregion

    //region Form
    createForm() {
        const generalForm = this.formBuilder.group({
            description: new FormControl(this.parkingBan?.description),
            end: new FormControl(this.parkingBan?.end?.toISOString().slice(0, 10)),
            location: new FormControl(this.parkingBan?.location),
            start: new FormControl(this.parkingBan?.start?.toISOString().slice(0, 10)),
        });

        const attachmentsForm = this.formBuilder.array([]);
        const exceptionsForm = this.formBuilder.array([]);
        const signScansForm = this.formBuilder.array([]);

        this.parkingBanForm = this.formBuilder.group({
            exceptionsForm,
            generalForm,
            isCompleted: new FormControl(this.parkingBan.isCompleted),
            attachmentsForm,
            signScansForm,
        });

        if (this.parkingBan?.photos) {
            this.parkingBan.photos.forEach((photo) => {
                this.attachments.push(
                    this.formBuilder.group({
                        attachmentId: new FormControl(photo.id),
                        attachmentImage: new FormControl(photo.url),
                        attachmentDescription: new FormControl(photo.description),
                    }),
                );
            });
        }

        if (this.parkingBan?.exceptions) {
            this.parkingBan?.exceptions.forEach((exception) =>
                exception?.photos.forEach((photo) =>
                    this.exceptions.push(
                        this.formBuilder.group({
                            exceptionId: new FormControl(exception.id),
                            exceptionImage: new FormControl(photo.url),
                            exceptionLicensePlate: new FormControl(exception.licensePlate),
                        }),
                    ),
                ),
            );
        }

        if (this.parkingBan?.signScans) {
            this.parkingBan?.signScans.forEach((signScan) =>
                this.signScans.push(
                    this.formBuilder.group({
                        signScanId: new FormControl(signScan.id),
                        signScanCode: new FormControl(signScan.signCode),
                    }),
                ),
            );
        }
    }
    //endregion

    //region Map
    openMapsDialog(event: Event) {
        this.mapsDialog.open(event);
    }

    async onSaveModal(coordinates: ICoordinate) {
        const addressWithTimeZone = await firstValueFrom(this.locationApi.getAddressFromCoordinates$(coordinates));
        const location = {
            address: addressWithTimeZone.address,
            coordinate: coordinates,
        };

        this.parkingBanForm.get("generalForm").get("location").patchValue(location);
        const locationUpdater = new LocationUpdater({
            ...this.parkingBan.location,
            coordinate: location.coordinate,
            address: location.address,
        });
        await firstValueFrom(this.locationApi.update$(locationUpdater));
    }
    //endregion

    //region QR Code
    async onQrCodeScanned(event: string) {
        const organizationId =
            this.assignment.project?.parentProject?.organizationId ?? this.assignment.project?.organizationId;

        const parkingBanSignScan = await this.parkingBanService.createParkingBanSignScan(
            this.parkingBan,
            organizationId,
            this.location.coordinate,
            event,
        );
        await this.fetchParkingBan(this.assignment.id, this.parkingBan.id);

        const signScanForm = this.formBuilder.group({
            signScanId: new FormControl(parkingBanSignScan.id),
            signScanCode: new FormControl(event),
        });

        this.signScans.push(signScanForm);
    }

    async onQrCodeDelete(signScanId: number) {
        await this.parkingBanService.deleteParkingBanSignScan(signScanId);
        await this.fetchParkingBan(this.assignment.id, this.parkingBan.id);

        this.signScans.removeAt(
            this.signScans.controls.findIndex((control) => control.get("signScanId").value === signScanId),
        );
    }
    //endregion

    //region Exception
    exceptionPhotoInputChanged(event: any) {
        const cameraInput = document.getElementById("exceptionPhotoInput") as HTMLInputElement;
        if (event.target.files && event.target.files.length === 1) {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = async (fileLoadend) => {
                const licensePlate = `1-ABC-${this.exceptions.length + 1}`;

                const organizationId =
                    this.assignment.project?.parentProject?.organizationId ?? this.assignment.project?.organizationId;

                const parkingBanException = await this.parkingBanService.createParkingBanException(
                    this.parkingBan,
                    licensePlate,
                    organizationId,
                );

                const attachment = await this.parkingBanService.createParkingBanExceptionAttachment(
                    parkingBanException,
                    fileLoadend.target.result as string,
                );
                await this.fetchParkingBan(this.assignment.id, this.parkingBan.id);

                const exceptionsForm = this.formBuilder.group({
                    exceptionId: new FormControl(parkingBanException.id),
                    exceptionImage: new FormControl(attachment.url),
                    exceptionLicensePlate: new FormControl(licensePlate),
                });

                this.exceptions.push(exceptionsForm);
                cameraInput.value = null;
            };
        }
    }

    async onExceptionDelete(exceptionId: number) {
        await this.parkingBanService.deleteParkingBanException(exceptionId);
        await this.fetchParkingBan(this.assignment.id, this.parkingBan.id);

        this.exceptions.removeAt(
            this.exceptions.controls.findIndex((control) => control.get("exceptionId").value === exceptionId),
        );
    }

    //endregion

    //region Attachment

    async addPhoto(inputId: string) {
        const cameraInput = document.getElementById(inputId) as HTMLInputElement;
        if (!cameraInput) this.toastService.showError("Camera is not working, please try again later.");
        cameraInput.click();
    }

    photoInputChanged(event: any) {
        const cameraInput = document.getElementById("photoInput") as HTMLInputElement;
        if (event.target.files && event.target.files.length === 1) {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = async (fileLoadend) => {
                const attachment = await this.parkingBanService.createParkingBanAttachment(
                    this.parkingBan,
                    fileLoadend.target.result as string,
                    "",
                );
                await this.fetchParkingBan(this.assignment.id, this.parkingBan.id);

                const formGroup = this.formBuilder.group({
                    attachmentId: new FormControl(attachment.id),
                    attachmentImage: new FormControl(attachment.url),
                    attachmentDescription: new FormControl(""),
                });

                this.attachments.push(formGroup);
                cameraInput.value = null;
            };
        }
    }

    async onParkingBanAttachmentDelete(attachmentId: number) {
        await this.parkingBanService.deleteParkingBanAttachment(attachmentId);
        await this.fetchParkingBan(this.assignment.id, this.parkingBan.id);

        this.attachments.removeAt(
            this.attachments.controls.findIndex((control) => control.get("attachmentId").value === attachmentId),
        );
    }
    //endregion

    //region Api calls
    async fetchPlannedAssignment(plannedAssignmentId: number) {
        if (!plannedAssignmentId) {
            this.router.navigate(["/assignments"]);
        }

        this.plannedAssignment = await this.assignmentService.fetchPlannedAssignment(plannedAssignmentId);
        this.assignment = this.plannedAssignment.assignment;
    }

    async fetchParkingBan(assignmentId: number, parkingBanId: number) {
        if (!parkingBanId) {
            this.router.navigate(["/assignment", assignmentId, "parking-bans"]);
        }

        this.parkingBan = await this.parkingBanService.getParkingBan(parkingBanId);
    }
    //endregion
}
