import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
    selector: "m-page-default",
    standalone: true,
    imports: [],
    templateUrl: "./default.component.html",
    styleUrl: "./default.component.scss",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefaultComponent {}
