import { AfterViewInit, Component, HostListener, inject, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ConfigurationService, LocalStorageService, PromptUpdateService } from "@ramudden/services";
import { ModalService, ToastComponent } from "@ramudden/ui";
import moment from "moment";
import { environment } from "../environments/environment";
import { HeaderComponent } from "./components/header/header.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { DefaultComponent } from "./layout/page/default/default.component";
import { AuthenticationService } from "./services/generic/authentication.service";
import { DomainDataService } from "./services/generic/domain-data.service";
import { GlobalEventsService } from "./services/generic/global-events.service";

@Component({
    standalone: true,
    imports: [RouterModule, DefaultComponent, HeaderComponent, ToastComponent, LoaderComponent],
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrl: "./app.component.scss",
})
export class AppComponent implements OnInit, AfterViewInit {
    @ViewChild("modalContainer", { read: ViewContainerRef, static: true }) modalContainer!: ViewContainerRef;

    isIframe = false;

    private readonly authenticationService = inject(AuthenticationService);
    private readonly configurationService = inject(ConfigurationService);
    private readonly domainDataService = inject(DomainDataService);
    private readonly globalEventsService = inject(GlobalEventsService);
    private readonly localStorageService = inject(LocalStorageService);
    private readonly promptUpdateService = inject(PromptUpdateService);
    private readonly translate = inject(TranslateService);
    private readonly modalService = inject(ModalService);

    ngOnInit(): void {
        this.authenticationService.initialize();

        this.promptUpdateService.subscribeToUpdates();
        this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal

        // Fallback when app_initializer failed
        AppComponent.attemptStartup(this.configurationService, this.domainDataService);

        this.configureTranslations();
    }

    ngAfterViewInit() {
        this.modalService.setRootViewContainerRef(this.modalContainer);
    }

    private configureTranslations() {
        // this language will be used as a fallback when a translation isn't found in the current language
        const languages = ["nl", "en", "de", "fr", "sv", "no"];
        const defaultLang = "en";
        this.translate.setDefaultLang(defaultLang);

        // the lang to use, if the lang isn't available, it will use the current loader to get them
        let initialLanguage = this.localStorageService.getItem("language") || defaultLang;

        if (!languages.contains(initialLanguage)) {
            initialLanguage = defaultLang;
        }

        this.translate.use(initialLanguage);

        this.translate.onLangChange.subscribe(() => {
            this.localStorageService.setItem("language", this.translate.currentLang);

            let locale = "en-GB";
            if (this.translate.currentLang === "nl") {
                locale = "nl-BE";
            } else if (this.translate.currentLang === "fr") {
                locale = "fr";
            } else if (this.translate.currentLang === "de") {
                locale = "de";
            } else if (this.translate.currentLang === "sv") {
                locale = "sv";
            } else if (this.translate.currentLang === "no") {
                locale = "no";
            }
            moment.locale(locale);
        });

        this.globalEventsService.authorizationInfo$.subscribe((authorizationInfo) => {
            if (authorizationInfo) {
                const language = authorizationInfo.user.languageId;
                if (language) {
                    this.translate.use(language);
                }
            }
        });
    }

    static async attemptStartup(
        configService: ConfigurationService,
        domainDataService: DomainDataService,
    ): Promise<void> {
        try {
            if (!configService.configuration) {
                await configService.loadConfiguration(environment);
            }

            if (!domainDataService.domainData) {
                await domainDataService.load();
            }
        } catch (error) {
            if (!environment.production) {
                console.error(error);
            }
        }
    }

    @HostListener("window:keydown", ["$event"])
    handleKeyDown(event: KeyboardEvent) {
        // Disable F5 and Ctrl+R for refresh
        if (event.key === "F5" || (event.ctrlKey && event.key === "r")) {
            event.preventDefault();
        }
    }
}
