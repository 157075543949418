import { inject, Injectable } from "@angular/core";
import { ISelectedWorker } from '../components/worker/worker.component';
import { ISelectedVehicle } from '../pages/setup/dialogs/vehicle/vehicle-dialog.component';
import { IPlannedEvent } from "@ramudden/data-access/models/planned-event";
import { LocalStorageService } from "@ramudden/services";

export type AppConfig = {
    language: string;
    isConfigured: boolean;
    plannedEventId: number;
};

const configKey = "configKey";
const selectedPlannedEventKey = "selectedPlannedEventKey";
const selectedWorkerKey = "selectedWorkerKey";
const selectedWorkersKey = "selectedWorkersKey";
const selectedVehiclesKey = "selectedVehiclesKey";

@Injectable({
    providedIn: "root",
})
export class AppService {
    private readonly localStorageService = inject(LocalStorageService);

    config: AppConfig = undefined;
    plannedEvent: IPlannedEvent = undefined;
    selectedWorker: ISelectedWorker = undefined;
    selectedWorkers: ISelectedWorker[] = [];
    selectedVehicles: ISelectedVehicle[] = [];

    constructor() {
        this.restoreState();
    }

    public saveState() {
        this.localStorageService.setItem(configKey, JSON.stringify(this.config));
        this.localStorageService.setItem(selectedPlannedEventKey, JSON.stringify(this.plannedEvent));
        this.localStorageService.setItem(selectedWorkerKey, JSON.stringify(this.selectedWorker));
        this.localStorageService.setItem(selectedWorkersKey, JSON.stringify(this.selectedWorkers));
        this.localStorageService.setItem(selectedVehiclesKey, JSON.stringify(this.selectedVehicles));
    }

    public restoreState() {
        this.config = JSON.parse(this.localStorageService.getItem(configKey));
        this.plannedEvent = JSON.parse(this.localStorageService.getItem(selectedPlannedEventKey));
        this.selectedWorker = JSON.parse(this.localStorageService.getItem(selectedWorkerKey));
        this.selectedWorkers = JSON.parse(this.localStorageService.getItem(selectedWorkersKey));
        this.selectedVehicles = JSON.parse(this.localStorageService.getItem(selectedVehiclesKey));
    }

    public clearState() {
        this.config = undefined;
        this.localStorageService.removeItem(configKey);
        this.plannedEvent = undefined;
        this.localStorageService.removeItem(selectedPlannedEventKey);
        this.selectedWorker = undefined;
        this.localStorageService.removeItem(selectedWorkerKey);
        this.selectedWorkers = [];
        this.localStorageService.removeItem(selectedWorkersKey);
        this.selectedVehicles = [];
        this.localStorageService.removeItem(selectedVehiclesKey);
    }
}
