import { Component, inject, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { SubscriptionManager } from "@ramudden/core/utils";
import { IPlannedAssignment } from "@ramudden/data-access/models/planned-event";
import { SvgComponent } from "@ramudden/ui";
import { DefaultComponent } from "../../layout/content/default/default.component";
import { AppService } from "../../services/app.service";
import { AssignmentService } from "../../services/assignment.service";
import { TitleService } from "../../services/title.service";
import { ImageViewerDialogComponent } from "../assignment-container/assignment-details/dialogs/image-viewer/image-viewer-dialog.component";
import { PdfViewerDialogComponent } from "../assignment-container/assignment-details/dialogs/pdf-viewer/pdf-viewer-dialog.component";
import { AssignmentCardComponent } from "./assignment-card/assignment-card.component";

@Component({
    selector: "app-assignments",
    standalone: true,
    templateUrl: "./assignments.component.html",
    styleUrl: "./assignments.component.scss",
    imports: [
        DefaultComponent,
        AssignmentCardComponent,
        SvgComponent,
        TranslateModule,
        ImageViewerDialogComponent,
        PdfViewerDialogComponent,
    ],
})
export class AssignmentsComponent implements OnInit, OnDestroy {
    private readonly appService = inject(AppService);
    private readonly router = inject(Router);
    private readonly assignmentService = inject(AssignmentService);
    private readonly titleService = inject(TitleService);
    private readonly subscriptionManager = new SubscriptionManager();

    todayAssignments: IPlannedAssignment[] = [];
    tomorrowAssignments: IPlannedAssignment[] = [];

    async ngOnInit() {
        this.subscriptionManager.add(
            "refreshClicked",
            this.titleService.refreshClicked$.subscribe(() => {
                this.refetchPlannedEvents();
            }),
        );

        this.titleService.title = "Markings as a Service";

        if (!this.appService.config.isConfigured) {
            this.router.navigate(["/setup"]);
        }

        this.todayAssignments = await this.assignmentService.getTodayAssignments();
        this.tomorrowAssignments = await this.assignmentService.getTomorrowAssignments();
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
    }

    protected onAssignmentClicked(plannedAssignment: IPlannedAssignment) {
        this.assignmentService.selectedPlannedAssignment = plannedAssignment;
        this.router.navigate(["assignment", plannedAssignment.id, "details"]);
    }

    protected async refetchPlannedEvents() {
        this.todayAssignments = await this.assignmentService.getTodayAssignments();
        this.tomorrowAssignments = await this.assignmentService.getTomorrowAssignments();
    }
}
