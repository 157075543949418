<content-default>
    <ng-container body>
        <div class="flex flex-column gap-20">
            <details
                class="accordion"
                open
            >
                <summary class="accordion__summary">
                    <h2>{{ "assignmentsPage.today" | translate }}</h2>

                    <m-svg
                        class="accordion__icon"
                        iconName="marker-down"
                    />
                </summary>

                @if (todayAssignments.length) {
                    <div class="accordion__content flex flex-column gap-16 mt-16">
                        @for (assignment of todayAssignments; track $index) {
                            <app-assignment-card
                                [imageViewer]="imageViewer"
                                [pdfViewer]="pdfViewer"
                                [plannedAssignment]="assignment"
                                (assignmentClicked)="onAssignmentClicked($event)"
                            />
                        }
                    </div>
                }
            </details>

            <details class="accordion">
                <summary class="accordion__summary">
                    <h2>{{ "assignmentsPage.tomorrow" | translate }}</h2>

                    <m-svg
                        class="accordion__icon"
                        iconName="marker-down"
                    />
                </summary>

                @if (tomorrowAssignments.length) {
                    <div class="accordion__content flex flex-column gap-16 mt-16">
                        @for (assignment of tomorrowAssignments; track $index) {
                            <app-assignment-card
                                [imageViewer]="imageViewer"
                                [pdfViewer]="pdfViewer"
                                [plannedAssignment]="assignment"
                                (assignmentClicked)="onAssignmentClicked($event)"
                            />
                        }
                    </div>
                }
            </details>
        </div>
    </ng-container>
</content-default>

<app-image-viewer-dialog #imageViewer />
<app-pdf-viewer-dialog #pdfViewer />
