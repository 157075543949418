import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionStatus } from "@azure/msal-browser";
import { TranslateService } from "@ngx-translate/core";
import { Constants } from "@ramudden/core/constants";
import { UserApi } from "@ramudden/data-access/resource/user.api";
import { ConfigurationService, LocalStorageService, SessionStorageService, WebsiteService } from "@ramudden/services";
import { Observable, firstValueFrom } from "rxjs";
import { filter, first } from "rxjs/operators";
import { GlobalEventsService } from "./global-events.service";

@Injectable({
    providedIn: "root",
})
export class AuthenticationService {
    constructor(
        private router: Router,
        private msalService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private readonly translateService: TranslateService,
        private readonly sessionStorageService: SessionStorageService,
        private readonly localStorageService: LocalStorageService,
        private readonly userApi: UserApi,
        private readonly configurationService: ConfigurationService,
        private readonly websiteService: WebsiteService,
        private readonly globalEventsService: GlobalEventsService,
    ) {}
    private clearData() {
        this.sessionStorageService.clear();
    }
    //#region MSAL

    init() {
        // this.msalService.initialize();
        this.msalService.handleRedirectObservable().subscribe();

        this.msalBroadcastService.msalSubject$
            .pipe(
                first(
                    (value) =>
                        value.eventType === EventType.LOGIN_SUCCESS ||
                        value.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
                        value.eventType === EventType.SSO_SILENT_SUCCESS,
                ),
            )
            .subscribe((_result: EventMessage) => {
                this.globalEventsService.setIsAuthenticated(true);
                this.authorize();
            });
        this.msalBroadcastService.msalSubject$
            .pipe(first((value) => value.eventType === EventType.ACQUIRE_TOKEN_FAILURE))
            .subscribe((_result: EventMessage) => {
                this.msalService.logoutRedirect({
                    onRedirectNavigate: (_url) => {
                        // Return false if you would like to stop navigation after local logout
                        return false;
                    },
                });
            });
        this.msalBroadcastService.msalSubject$
            .pipe(first((value) => value.eventType === EventType.SSO_SILENT_FAILURE))
            .subscribe((_result: EventMessage) => {
                if (!this.globalEventsService.getIsAuthenticated()) {
                    this.globalEventsService.setIsAuthenticated(false);
                }
            });
        this.msalService
            .ssoSilent({
                scopes: [this.configurationService.configuration.azureAuthenticationScope, "openid"],
                extraQueryParameters: { environment: this.websiteService.getB2cEnvironment() },
            })
            .subscribe({
                next: (_result: AuthenticationResult) => {},
                error: (_error) => {},
            });
    }

    initialize(): void {
        this.msalService.handleRedirectObservable().subscribe();
        this.msalService.initialize().subscribe(() => {
            this.msalBroadcastService.msalSubject$
                .pipe(
                    first(
                        (value) =>
                            value.eventType === EventType.LOGIN_SUCCESS ||
                            value.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
                            value.eventType === EventType.SSO_SILENT_SUCCESS,
                    ),
                )
                .subscribe((_result: EventMessage) => {
                    this.globalEventsService.setIsAuthenticated(true);
                    this.authorize();
                });
            this.msalBroadcastService.msalSubject$
                .pipe(first((value) => value.eventType === EventType.ACQUIRE_TOKEN_FAILURE))
                .subscribe((_result: EventMessage) => {
                    this.msalService.logoutRedirect({
                        onRedirectNavigate: (_url) => {
                            // Return false if you would like to stop navigation after local logout
                            return false;
                        },
                    });
                });
            this.msalBroadcastService.msalSubject$
                .pipe(first((value) => value.eventType === EventType.SSO_SILENT_FAILURE))
                .subscribe((_result: EventMessage) => {
                    if (!this.globalEventsService.getIsAuthenticated()) {
                        this.globalEventsService.setIsAuthenticated(false);
                    }
                });
            this.msalService
                .ssoSilent({
                    scopes: [this.configurationService.configuration.azureAuthenticationScope, "openid"],
                    extraQueryParameters: { environment: this.websiteService.getB2cEnvironment() },
                })
                .subscribe({
                    next: (_result: AuthenticationResult) => {},
                    error: (_error) => {},
                });
        });
    }

    authorize() {
        return firstValueFrom(this.userApi.getSelf$())
            .then((user) => {
                if (user) {
                    this.globalEventsService.setAuthorizationInfo(user);
                    this.redirectToCallbackRoute();
                }
            })
            .catch((error) => {
                const message = JSON.stringify(error);
                console.error(`An error occured while trying to authorize: ${message}`);
                this.signoutSilently();
            });
    }

    acquireTokenSilent$(): Observable<AuthenticationResult> {
        // Sets account as active account or first account
        let account: AccountInfo;
        if (this.msalService.instance.getActiveAccount()) {
            this.msalService.getLogger().verbose("Interceptor - active account selected");
            account = this.msalService.instance.getActiveAccount();
        } else {
            this.msalService.getLogger().verbose("Interceptor - no active account, fallback to first account");
            account = this.msalService.instance.getAllAccounts()[0];
        }
        return this.msalService.acquireTokenSilent({
            scopes: [this.configurationService.configuration.azureAuthenticationScope, "openid"],
            account,
            extraQueryParameters: { environment: this.websiteService.getB2cEnvironment() },
        });
    }

    login() {
        let language = this.translateService.currentLang;
        if (!language) {
            language = "en";
        }

        const loginRequest = {
            scopes: [this.configurationService.configuration.azureAuthenticationScope, "openid"],
            extraQueryParameters: { ui_locales: language, environment: this.websiteService.getB2cEnvironment() },
        };
        this.msalBroadcastService.inProgress$
            .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None))
            .subscribe(() => {
                this.msalService.loginRedirect(loginRequest);
            });
    }

    signoutSilently() {
        this.logoutMsal();
        sessionStorage.removeItem(Constants.callbackRoute);
        this.clearData();
        // this.currentUser = null;
        // this.globalEventsService.setIsAuthenticated(false);
        // this.globalEventsService.setAuthorizationInfo(null);
        // this.router.navigate(["/"]);
    }

    private logoutMsal() {
        const logoutRequest = {
            account: this.msalService.instance.getActiveAccount(),
        };
        this.msalService.logoutRedirect(logoutRequest);
    }

    private redirectToCallbackRoute() {
        // retrieve last route from local storage
        let callbackRoute = this.localStorageService.getItem(Constants.callbackRoute);
        this.localStorageService.removeItem(Constants.callbackRoute);
        let queryParameters: any = null;
        if (callbackRoute) {
            // Handle query params
            if (callbackRoute.contains("?")) {
                const splitCallback = callbackRoute.split("?");
                callbackRoute = splitCallback[0];

                // Query params example: id=5&x=y
                queryParameters = {};

                const queryParamPairs = splitCallback[1].split("&");
                for (const queryParam of queryParamPairs) {
                    const queryParamSplit = queryParam.split("=");
                    const queryParamKey = queryParamSplit[0];
                    const queryParamValue = queryParamSplit[1];

                    queryParameters[queryParamKey] = queryParamValue;
                }
            }

            // Redirect
            if (callbackRoute !== "/callback") {
                if (queryParameters) {
                    this.router.navigate([callbackRoute], { queryParams: queryParameters });
                } else {
                    this.router.navigate([callbackRoute]);
                }
            } else {
                this.router.navigate(["/"]);
            }
        }
    }
    //#endregion
}
