import { CommonModule } from "@angular/common";
import { Component, inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import {
    FilterDescriptor,
    FilterOperator,
    SearchParameters,
    ServiceRequestOptions,
} from "@ramudden/data-access/models/search";
import { ITask, TaskStatus, TaskType } from "@ramudden/data-access/models/task";
import { TaskApi } from "@ramudden/data-access/resource/task.api";
import { SvgComponent } from "@ramudden/ui";
import moment from "moment/moment";
import { firstValueFrom } from "rxjs";
import { DefaultComponent } from "../../../layout/content/default/default.component";
import { AppService } from "../../../services/app.service";
import { AssignmentService } from "../../../services/assignment.service";
import { TaskCardComponent } from "./task-card/task-card.component";

@Component({
    selector: "app-assignment-tasks",
    standalone: true,
    imports: [CommonModule, DefaultComponent, SvgComponent, TaskCardComponent, TranslateModule],
    templateUrl: "./assignment-tasks.component.html",
    styleUrl: "./assignment-tasks.component.scss",
})
export class AssignmentTasksComponent implements OnInit {
    private readonly appService = inject(AppService);
    private readonly assignmentService = inject(AssignmentService);
    private readonly taskApi = inject(TaskApi);
    private readonly router = inject(Router);

    protected tasks: ITask[] = [];

    async ngOnInit() {
        this.tasks = await this.fetchTasks();
    }

    get siteActive() {
        const plannedAssignment = this.assignmentService.selectedPlannedAssignment;
        return (
            plannedAssignment.currentTeamStatus?.teamArrival !== undefined &&
            plannedAssignment.currentTeamStatus?.teamDeparture === undefined
        );
    }

    createTask() {
        this.router.navigate(["/assignment", this.assignmentService.selectedPlannedAssignment.id, "task", "new"]);
    }

    async onRefreshTasks() {
        this.tasks = await this.fetchTasks();
    }

    //region Fetch Tasks
    private async fetchTasks(): Promise<ITask[]> {
        const assignmentId = this.assignmentService.selectedPlannedAssignment.assignmentId;
        if (assignmentId <= 0) return Promise.resolve([] as ITask[]);

        const serviceRequestOptions = new ServiceRequestOptions();
        serviceRequestOptions.includes.add("Task", "Location");
        serviceRequestOptions.includes.add("Task", "CurrentStatus");
        serviceRequestOptions.includes.add("Task", "Assignment");

        let filterValue = "";
        if (this.appService.selectedWorkers.length > 0) {
            const teamMemberIds = this.appService.selectedWorkers.map((worker) => worker.id);
            filterValue = teamMemberIds.join("|");
        }

        //region Active search parameters
        const activeSearchParameters = new SearchParameters();
        activeSearchParameters.filter = [
            new FilterDescriptor("Assignment", assignmentId, FilterOperator.equals),
            new FilterDescriptor("TaskStatusId", TaskStatus.InProgress, FilterOperator.equals),
        ];

        if (
            this.appService.selectedWorker?.canDoControl !== undefined &&
            !this.appService.selectedWorker?.canDoControl
        ) {
            activeSearchParameters.filter.push(
                new FilterDescriptor("TaskTypeId", TaskType.Control, FilterOperator.notEquals),
            );
        }

        if (filterValue.length > 0) {
            activeSearchParameters.filter.push(new FilterDescriptor("Worker", filterValue, FilterOperator.in));
        }
        //endregion

        //region Scheduled search parameters
        const scheduledSearchParameters = new SearchParameters();
        scheduledSearchParameters.filter = [
            new FilterDescriptor("Assignment", assignmentId, FilterOperator.equals),
            new FilterDescriptor("TaskStatusId", TaskStatus.Finished, FilterOperator.notEquals),
            new FilterDescriptor("AvailableFrom", moment().format("YYYY/MM/DD"), FilterOperator.lessThanOrEqualTo),
        ];

        if (
            this.appService.selectedWorker?.canDoControl !== undefined &&
            !this.appService.selectedWorker?.canDoControl
        ) {
            scheduledSearchParameters.filter.push(
                new FilterDescriptor("TaskTypeId", TaskType.Control, FilterOperator.notEquals),
            );
        }

        if (filterValue.length > 0) {
            scheduledSearchParameters.filter.push(new FilterDescriptor("WorkerTasks", filterValue, FilterOperator.in));
        }
        //endregion

        //region Finished search parameters
        const finishedSearchParameters = new SearchParameters();
        finishedSearchParameters.filter = [
            new FilterDescriptor("Assignment", assignmentId, FilterOperator.equals),
            new FilterDescriptor("TaskStatusId", TaskStatus.Finished, FilterOperator.equals),
            new FilterDescriptor(
                "End",
                moment().subtract(1, "days").format("YYYY/MM/DD"),
                FilterOperator.greaterThanOrEqualTo,
            ),
        ];

        if (
            this.appService.selectedWorker?.canDoControl !== undefined &&
            !this.appService.selectedWorker?.canDoControl
        ) {
            finishedSearchParameters.filter.push(
                new FilterDescriptor("TaskTypeId", TaskType.Control, FilterOperator.notEquals),
            );
        }

        if (filterValue.length > 0) {
            finishedSearchParameters.filter.push(new FilterDescriptor("Worker", filterValue, FilterOperator.in));
        }
        //endregion

        const activeTasks = (await firstValueFrom(this.taskApi.search$(activeSearchParameters, serviceRequestOptions)))
            .data;
        const scheduledTasks = (
            await firstValueFrom(this.taskApi.search$(scheduledSearchParameters, serviceRequestOptions))
        ).data;
        const finishedTasks = (
            await firstValueFrom(this.taskApi.search$(finishedSearchParameters, serviceRequestOptions))
        ).data;
        return [...activeTasks, ...scheduledTasks, ...finishedTasks].sort((a, b) => {
            const aTime: number = moment(a.name, "YYYYMMDD_HHmmss").toDate().getTime();
            const bTime: number = moment(b.name, "YYYYMMDD_HHmmss").toDate().getTime();
            return bTime - aTime;
        });
    }
    //endregion
}
