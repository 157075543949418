import { HttpResponse } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ToastService } from "@ramudden/ui";

@Injectable({
    providedIn: "root",
})
export class ErrorService {
    private readonly toastService = inject(ToastService);
    private readonly router = inject(Router);

    async handleError(response: Response | HttpResponse<any>) {
        if (!response) return;

        if (response.status === 503 || response.status === 0) {
            return;
        }

        if (response.status === 404) return;

        const error = await this.getErrorString(response);
        if (!error) return;

        this.toastService.showError(error);
    }

    async getErrorString(response: any): Promise<string> {
        let error = response.statusText || response.message || response;

        let respError = (response as any).error;
        if (respError) {
            if (typeof respError === "string") {
                error = respError;

                respError = JSON.parse(respError);
            }

            if (respError.Message === "") respError.Message = "errors.general";

            if (respError.Message && typeof respError.Message === "string") {
                error = respError.Message;
            }
        } else if (response.json) {
            const bodyJson = await response.json();
            if (bodyJson) {
                if (bodyJson.Message && typeof bodyJson.Message === "string") {
                    error = bodyJson.Message;
                } else if (typeof bodyJson === "string") {
                    error = bodyJson;
                }
            }
        }

        if (respError.type === "application/json") {
            const reader = new FileReader();

            reader.onloadend = () => {
                error =
                    reader.result instanceof ArrayBuffer
                        ? "Result was an ArrayBuffer"
                        : JSON.parse(reader.result as string).Message;
                this.toastService.showError(error || "errors.general");
            };

            reader.readAsText(respError);
            return null;
        }

        return error;
    }
}
