import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

/**
 * Service to manage the loader state, both automatically and manually
 * Automatically there is an interceptor that will show the loader on every request
 * Manually you can show and hide the loader by calling the methods showManually and hideManually
 */
@Injectable({
    providedIn: "root",
})
export class LoaderService {
    private activeRequests = 0;
    private manualTriggers = 0;
    private loading = new BehaviorSubject<boolean>(false);
    loading$ = this.loading.asObservable();

    //region Automatic
    show(): void {
        this.activeRequests++;
        this.updateLoaderState();
    }

    hide(): void {
        this.activeRequests = Math.max(this.activeRequests - 1, 0);
        this.updateLoaderState();
    }
    //endregion

    //region Manual
    showManually(): void {
        this.manualTriggers++;
        this.updateLoaderState();
    }

    hideManually(): void {
        this.manualTriggers = Math.max(this.manualTriggers - 1, 0);
        this.updateLoaderState();
    }
    //endregion

    /**
     * Determine the loader state based on both counters
     * @private
     */
    private updateLoaderState(): void {
        const shouldShow = this.activeRequests > 0 || this.manualTriggers > 0;
        this.loading.next(shouldShow);
    }
}
