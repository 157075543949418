import { CommonModule } from "@angular/common";
import { Component, inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { IAssignment } from "@ramudden/data-access/models/assignment";
import { ICoordinate, ILocation } from "@ramudden/data-access/models/location";
import { IParkingBan } from "@ramudden/data-access/models/parking-ban";
import { LocationApi } from "@ramudden/data-access/resource/location.api";
import { SvgComponent, ToastService } from "@ramudden/ui";
import moment from "moment/moment";
import { firstValueFrom } from "rxjs";
import { MapComponent } from "../../../../components/map/map.component";
import { QrScannerComponent } from "../../../../components/qr-scanner/qr-scanner.component";
import { StepIndexDirective } from "../../../../components/stepper/step-index.directive";
import { StepperComponent } from "../../../../components/stepper/stepper.component";
import { LayoutStepperComponent } from "../../../../layout/content/stepper/stepper.component";
import { AssignmentService } from "../../../../services/assignment.service";
import { LocationService } from "../../../../services/location.service";
import { ParkingBanService } from "../../../../services/parking-ban.service";
import { TitleService } from "../../../../services/title.service";

@Component({
    selector: "app-create-parking-ban",
    standalone: true,
    imports: [
        CommonModule,
        StepperComponent,
        StepIndexDirective,
        ReactiveFormsModule,
        SvgComponent,
        MapComponent,
        LayoutStepperComponent,
        QrScannerComponent,
        TranslateModule,
    ],
    templateUrl: "./create-parking-ban.component.html",
})
export class CreateParkingBanComponent implements OnInit, OnDestroy {
    @ViewChild(MapComponent) googleMap!: MapComponent;

    private readonly formBuilder = inject(FormBuilder);
    private readonly locationApi = inject(LocationApi);
    private readonly route = inject(ActivatedRoute);
    private readonly router = inject(Router);
    private readonly titleService = inject(TitleService);
    protected readonly assignmentService = inject(AssignmentService);
    protected readonly locationService = inject(LocationService);
    protected readonly parkingBanService = inject(ParkingBanService);
    private readonly toastService = inject(ToastService);

    parkingBanForm: FormGroup;
    signScanFrom: FormGroup;
    exceptionsForm: FormGroup;

    assignment: IAssignment;
    marker: google.maps.marker.AdvancedMarkerElement;

    //region Implements
    async ngOnInit() {
        const plannedAssignmentId = this.route.snapshot.params["plannedAssignmentId"];
        if (!plannedAssignmentId) {
            this.router.navigate(["/assignments"]);
            return;
        }

        this.titleService.title = "Creëer parkeerverbod";
        this.titleService.goBack.set(["/assignment", plannedAssignmentId, "parking-bans"]);

        this.createFormParkingBan();
        this.createFromSignScan();
        this.createFormExceptions();
    }

    ngOnDestroy() {
        if (this.googleMap) {
            google.maps.event.clearInstanceListeners(this.googleMap.map);
        }
        if (this.marker) {
            google.maps.event.clearInstanceListeners(this.marker);
        }
    }
    //endregion

    //region Parking ban form
    //region Get
    get parkingBanAttachments() {
        return this.parkingBanForm.get("attachments") as FormArray;
    }

    get parkingBanLocation(): ILocation {
        return this.parkingBanForm.get("location").value;
    }

    get parkingBanAddress(): string {
        const address = this.parkingBanLocation?.address;
        if (address) {
            return this.locationService.convertToAddressString(address, true);
        }
        return "";
    }
    //endregion

    createFormParkingBan() {
        const name = moment().format("YYYYMMDD_HHmmss");

        this.parkingBanForm = this.formBuilder.group({
            name: new FormControl(name),
            description: new FormControl(""),
            start: new FormControl(""),
            end: new FormControl(""),
            location: new FormControl(""),
            attachments: this.formBuilder.array([]),
        });
    }

    //region Snapshot

    deleteAttachmentSnapshot(index: number) {
        this.parkingBanAttachments.removeAt(index);
    }
    //endregion

    //region Map
    async onMapLoaded() {
        if (!this.googleMap.map || this.marker) return;

        this.marker = await this.googleMap.createMarker(this.googleMap.coordinates, "", true);
        this.marker.addListener("dragend", () => {
            this.onCoordinatesChanged({
                latitude: this.marker.position.lat,
                longitude: this.marker.position.lng,
            } as ICoordinate);
        });
        this.googleMap.map.addListener("click", (event: google.maps.MapMouseEvent) => {
            // TODO: Add a check if user really wants to do it

            const coordinates = {
                latitude: event.latLng.lat(),
                longitude: event.latLng.lng(),
            };
            this.marker.position = event.latLng;
            this.onCoordinatesChanged(coordinates);
        });
    }

    async onCoordinatesChanged(coordinate: ICoordinate) {
        const addressWithTimeZone = await firstValueFrom(this.locationApi.getAddressFromCoordinates$(coordinate));
        const location = {
            address: addressWithTimeZone.address,
            coordinate: coordinate,
        };

        this.parkingBanForm.patchValue({
            location: location,
        });
    }
    //endregion

    //endregion

    //region Sign scans
    get signs() {
        return this.signScanFrom.get("signs") as FormArray;
    }

    createFromSignScan() {
        this.signScanFrom = this.formBuilder.group({
            signs: this.formBuilder.array([]),
        });
    }

    async onQrCodeScanned(qrCode: string) {
        const coordinates = await this.locationService.getCurrentCoordinates();
        const currentAddress = await this.locationService.getAddressFromCoordinates(coordinates);

        const location = {
            coordinate: coordinates,
            address: currentAddress.address,
        } as ILocation;

        const signForm = this.formBuilder.group({
            qrCodeNumber: new FormControl(qrCode),
            location: new FormControl(location),
        });

        this.signs.push(signForm);
    }

    deleteSignSnapshot(index: number) {
        this.signs.removeAt(index);
    }
    //endregion

    //region Exceptions
    get exceptions() {
        return this.exceptionsForm.get("exceptions") as FormArray;
    }

    createFormExceptions() {
        this.exceptionsForm = this.formBuilder.group({
            exceptions: this.formBuilder.array([]),
        });
    }

    deleteExceptionSnapshot(index: number) {
        this.exceptions.removeAt(index);
    }
    //endregion

    //region Stepper
    async onFinish() {
        const parkingBan = await this.createParkingBan();
        await this.uploadParkingBanAttachments(parkingBan);
        await this.createSignScans(parkingBan);
        await this.createExceptions(parkingBan);

        this.router.navigate([
            "/assignment",
            this.route.snapshot.params["plannedAssignmentId"],
            "parking-ban",
            parkingBan.id,
        ]);
    }
    //endregion

    //region API Calls
    async createParkingBan() {
        const assignment = this.assignmentService.selectedPlannedAssignment.assignment;

        const parkingBan = {
            id: null,
            name: this.parkingBanForm.get("name").value,
            description: this.parkingBanForm.get("description").value,
            start: this.parkingBanForm.get("start").value,
            end: this.parkingBanForm.get("end").value,
            location: this.parkingBanLocation,
            assignmentId: assignment.id,
            assignment: assignment,
            isCompleted: false,
        } as IParkingBan;

        return await this.parkingBanService.createParkingBan(parkingBan);
    }

    async uploadParkingBanAttachments(parkingBan: IParkingBan) {
        const attachments = this.parkingBanAttachments.value as any[];

        for (const attachment of attachments) {
            await this.parkingBanService.createParkingBanAttachment(
                parkingBan,
                attachment["attachmentImage"],
                attachment["attachmentDescription"],
            );
        }
    }

    async createSignScans(parkingBan: IParkingBan) {
        const signScans = this.signs.value;

        const organizationId =
            this.assignmentService.selectedPlannedAssignment.assignment?.project?.organizationId ??
            this.assignmentService.selectedPlannedAssignment.assignment?.parentAssignment?.project?.organizationId;

        for (const signScan of signScans) {
            await this.parkingBanService.createParkingBanSignScan(
                parkingBan,
                organizationId,
                signScan["location"].coordinate,
                signScan["qrCodeNumber"],
            );
        }
    }

    async createExceptions(parkingBan: IParkingBan) {
        const exceptions = this.exceptions.value;
        const organizationId =
            this.assignmentService.selectedPlannedAssignment.assignment?.project?.organizationId ??
            this.assignmentService.selectedPlannedAssignment.assignment?.parentAssignment?.project?.organizationId;

        for (const exception of exceptions) {
            const createdException = await this.parkingBanService.createParkingBanException(
                parkingBan,
                exception["exceptionLicensePlate"],
                organizationId,
            );
            await this.parkingBanService.createParkingBanExceptionAttachment(
                createdException,
                exception["exceptionImage"],
            );
        }
    }
    //endregion

    //region Helpers
    getAddress(location: ILocation) {
        return this.locationService.convertToAddressString(location.address);
    }
    //endregion

    async addPhoto(inputId: string) {
        const cameraInput = document.getElementById(inputId) as HTMLInputElement;
        if (!cameraInput) this.toastService.showError("Camera is not working, please try again later.");
        cameraInput.click();
    }

    photoInputChanged(event: any) {
        const cameraInput = document.getElementById("photoInput") as HTMLInputElement;
        if (event.target.files && event.target.files.length === 1) {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = (fileLoadend) => {
                const attachmentsForm = this.formBuilder.group({
                    attachmentImage: new FormControl(fileLoadend.target.result as string),
                    attachmentDescription: new FormControl(""),
                });

                this.parkingBanAttachments.push(attachmentsForm);
                cameraInput.value = null;
            };
        }
    }

    exceptionPhotoInputChanged(event: any) {
        const cameraInput = document.getElementById("exceptionPhotoInput") as HTMLInputElement;
        if (event.target.files && event.target.files.length === 1) {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = (fileLoadend) => {
                const numberPlate = `1-ABC-${this.exceptions.length + 1}`;
                const exceptionsForm = this.formBuilder.group({
                    exceptionImage: new FormControl(fileLoadend.target.result as string),
                    exceptionLicensePlate: new FormControl(numberPlate),
                });

                this.exceptions.push(exceptionsForm);
                cameraInput.value = null;
            };
        }
    }
}
