import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { ModalService, SvgComponent } from "@ramudden/ui";
import { AppService } from "../../services/app.service";
import { AssignmentService } from "../../services/assignment.service";
import { TitleService } from "../../services/title.service";

@Component({
    selector: "m-page-header",
    standalone: true,
    imports: [SvgComponent, RouterLink],
    templateUrl: "./header.component.html",
    styleUrl: "./header.component.scss",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
    private readonly assignmentService = inject(AssignmentService);
    private readonly router = inject(Router);
    protected readonly appService = inject(AppService);
    protected readonly titleService = inject(TitleService);
    private readonly modalService = inject(ModalService);

    public async refreshClicked() {
        this.titleService.refreshClicked$.next();
    }

    public userIconClick() {
        return this.router.navigate(["/setup"]);
    }

    public endWorkday() {
        const onOk = () => {
            this.assignmentService.endWorkday();
            this.appService.clearState();
            this.router.navigate(["/setup"]);
        };

        this.modalService.confirm("End workday", onOk);
    }
}
