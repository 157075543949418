import { CommonModule } from "@angular/common";
import { Component, input, output } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { SvgComponent } from "@ramudden/ui";

export type ISelectedWorker = {
    firstName: string;
    id: number;
    isActive: boolean;
    isTeamLeader: boolean;
    lastName: string;
    organizationId?: number;
    canDoControl?: boolean;
};

@Component({
    selector: "app-worker",
    standalone: true,
    imports: [CommonModule, FormsModule, SvgComponent],
    templateUrl: "./worker.component.html",
    styleUrl: "./worker.component.scss",
})
export class WorkerComponent {
    workerSelected = output<ISelectedWorker>();
    worker = input.required<ISelectedWorker>();
}
