export interface ModalConfig {
    title: string;
    body: string;
    type: "info" | "error" | "delete" | "alert";
    actions: ModalConfigAction[];
    doNotShowAgain?: boolean;
}

export interface ModalConfigAction {
    label: string;
    handler: () => void;
    class?: string[];
}
